import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async';
import {Container, Row, Col} from "react-bootstrap";
import {hover, text} from "../../styles/globalStyleVars";
import {Link} from "react-router-dom";
import FloatMenu from "../../components/FloatMenu";
import Pagination from "../../components/Pagination";
import DynamicHtmlRender from "../../components/DynamicHtmlRender";
import useAuthContext from "../../context/AuthContext";
import axios from "../../api/axios";
import {toast, ToastContainer} from "react-toastify";

const UserGuide = () => {
    const [userGuide, setUserGuide] = useState([]);
    const [getAds, setGetAds] = useState(null);
    const {csrf} = useAuthContext();
    const [pagination, setPagination] = useState({
        current_page: 1,
        last_page: 1,
        total: 0,
    });

    const fetchData = async (page = 1) => {
        try {
            await csrf();
            const response = await axios.get('/api/user-guide', {
                params: {page}
            });
            setUserGuide(response?.data?.data?.data);
            setPagination({
                current_page: response?.data?.pagination?.current_page || 1,
                last_page: response?.data?.pagination?.last_page || 1,
                total: response?.data?.pagination?.total || 0,
            });
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching user guide.');
        }
    };

    const fetchAdsList = async (category = 'user_guide') => {
        try {
            await csrf();
            const response = await axios.get('/api/ads-list', {
                params: {category}
            });
            setGetAds(response?.data);
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching ads.');
        }
    }

    useEffect(() => {
        fetchData();
        fetchAdsList();
    }, []);

    const handlePageChange = (page) => {
        fetchData(page);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>User Guide</title>
                <meta name="description" content="User Guide"/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container style={{paddingBottom: '40px'}}>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                        <Col md={8} sm={12}>
                            <Row>
                                <FloatMenu />

                                <Col sm={12}>
                                    <Row className={'mt-3'}>
                                        <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                            <p className={'daily-task-title'}>User Guide</p>
                                        </Col>

                                        {userGuide?.length > 0 ? (
                                            <>
                                                {userGuide.map((guide, index) => (
                                                    <Col md={4} sm={12} key={index}>
                                                        <Link to={`/user-guide/${guide?.slug}`} className={'user-guide-card'}>
                                                            <div className={'img-wrap'}>
                                                                <img
                                                                    src={guide?.image ? `${process.env.REACT_APP_API_URL}/storage/${guide?.image}` : '/images/static/blur.jpg'}
                                                                    alt={guide?.image_alt}
                                                                />
                                                            </div>
                                                            <p>{guide?.title}</p>
                                                        </Link>
                                                    </Col>
                                                ))}

                                                <Pagination
                                                    currentPage={pagination.current_page}
                                                    totalPages={pagination.last_page}
                                                    onPageChange={handlePageChange}
                                                />
                                            </>
                                        ) : (
                                            <Col sm={12} className={'text-center'}>
                                                <p className={'text-white'}>No user guides available.</p>
                                            </Col>
                                        )}

                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            <DynamicHtmlRender htmlString={getAds?.home_sidebar_right} />
                        </Col>
                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>

    );
};

const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;
  color: #ffffff;
  
  .daily-task-title {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    display: inline-block;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 80px;
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .user-guide-card {
    .img-wrap {
      padding-top: calc(200 / 300* 100%);
      position: relative;
      overflow: hidden;
      
      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    p {
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      margin: 10px 0 20px 0;
    }
  }
`;

export default UserGuide;
