import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "../api/axios";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    const csrf = () => axios.get('/sanctum/csrf-cookie');

    const getUser = async () => {
        try {
            const { data } = await axios.get('/api/user');
            setUser(data);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    const register = async ({...data}) => {
        await csrf();
        setLoading(true);
        try{
            const res = await axios.post('/register', data);
            await getUser();
            Swal.fire({
                icon: res?.data?.status,
                title: res?.data?.msg
            });
        }catch(e){
            handleErrors(e);
        }finally {
            setLoading(false);
        }
    }

    const login = async ({...data}) => {
        await csrf();
        setLoading(true);
        try {
            await axios.post('/login', data);
            await getUser();
            Swal.fire({
                icon: "success",
                title: "Login successful!",
                showConfirmButton: false,
                timer: 1500
            });
        } catch (e) {
            handleErrors(e);
        } finally {
            setLoading(false);
        }
    }

    const logout = async () => {
        await csrf();
        try{
            await axios.post('/logout');
            Swal.fire('Logout successful!');
            setUser(null);
        }catch(e){
            handleErrors(e);
        } finally {
            history.push('/');
        }
    }

    const handleErrors = (error) => {
        console.log(error);
        if (error?.response?.status === 422) {
            const validationErrors = error?.response?.data?.errors || {};
            Object.keys(validationErrors).forEach((field) => {
                validationErrors[field].forEach((message) => {
                    Swal.fire({
                        icon: "error",
                        title: message,
                        showConfirmButton: false,
                        timer: 3000
                    });
                });
            });
        } else if (error?.response?.status === 403) {
            const verifyEmail = error?.response?.data?.message || 'Authentication Error';
            Swal.fire({
                icon: "error",
                title: verifyEmail,
                showConfirmButton: false,
                timer: 3000
            });
        } else {
            console.error('Error:', error);
            Swal.fire('An unexpected error occurred.');
        }
    };

    useEffect(() => {
        if(!user){
            getUser();
        }
    }, []);

    return (
        <AuthContext.Provider value={{user, getUser, login, register, logout, csrf, loading}}>
            {children}
        </AuthContext.Provider>
    );
}

export default function useAuthContext(){
    return useContext(AuthContext);
}