import React, {useEffect, useState} from 'react';
import axios from "../../api/axios";
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async';
import {Container, Row, Col} from "react-bootstrap";
import {hover, text} from "../../styles/globalStyleVars";
import FloatMenu from "../../components/FloatMenu";
import {Link, useHistory} from "react-router-dom";
import DynamicHtmlRender from "../../components/DynamicHtmlRender";
import useAuthContext from "../../context/AuthContext";
import {toast, ToastContainer} from "react-toastify";

const GetBonus = () => {
    const [getBonus, setGetBouns] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [image, setImage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [getAds, setGetAds] = useState(null);
    const {user, csrf} = useAuthContext();
    const history = useHistory();

    const fetchData = async () => {
        try {
            await csrf();
            const response = await axios.get(`/api/get-bonus`);
            if (!response?.data?.success) {
                toast.error(response?.data?.message || 'No data Found.');
                history.push('/home');
            }
            setGetBouns(response?.data?.data || null);
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching get bonus.');
        }
    };

    const fetchAdsList = async (category = 'home') => {
        try {
            await csrf();
            const response = await axios.get('/api/ads-list', {
                params: {category}
            });
            setGetAds(response?.data);
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching ads.');
        }
    }

    useEffect(() => {
        fetchData();
        fetchAdsList();
    }, []);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const handleImageChange = (event) => {
        setImage(event.target.files[0]);
    };

    const handleBonus = async (event, bonusId, slug, price, type) => {
        event.preventDefault();
        setIsSubmitting(true);

        if (!selectedOption && type === 1) {
            toast.error("Select your answer");
            setIsSubmitting(false);
            return;
        }

        try {
            await csrf();
            const formData = new FormData();

            if (image) formData.append('image', image);
            formData.append('slug', slug);
            formData.append('task_item_id', bonusId);
            formData.append('price', price);
            formData.append('type', type);
            if (selectedOption) formData.append('selected_answer', selectedOption);

            const response = await axios.post('/api/get-bonus/submit', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response?.data?.success === false) {
                toast.error(response?.data?.message);
            } else {
                toast(response?.data?.message);
            }
        } catch (err) {
            if (err.response && err.response.status === 422) {
                const errors = err.response.data.errors;
                Object.keys(errors).forEach((field) => {
                    errors[field].forEach((message) => {
                        toast.error(message);
                    });
                });
            } else {
                console.error('Error fetching:', err);
                toast.error('An unexpected error occurred.');
            }
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Get Bonus</title>
                <meta name="description" content="Get Bonus"/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container style={{paddingBottom: '40px'}}>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            <DynamicHtmlRender htmlString={getAds?.sidebar_left} />
                        </Col>

                        <Col md={8} sm={12}>
                            <Row>
                                <FloatMenu />

                                <Col sm={12}>
                                    <Row className={'mt-3'}>
                                        <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                            <p className={'daily-task-title'}>Bonus</p>
                                        </Col>

                                        {getBonus && getBonus.length > 0 ? (
                                            getBonus.map((bonus, index) => (
                                                <React.Fragment key={index}>
                                                    {bonus?.type === 1 ? (
                                                        <form onSubmit={(e) => handleBonus(e, bonus.id, bonus.slug, bonus.price, bonus.type)}>
                                                            <Col sm={12}>
                                                                <div className="bonus-form">
                                                                    <div className={'pb-3'}>
                                                                        <h3>{bonus?.title}</h3>
                                                                        {bonus?.description}

                                                                        <div className="text-left mb-3 div-wrap">
                                                                            {bonus?.get_bonus_details?.[0]?.link ? (
                                                                                <Link
                                                                                    to={{ pathname: bonus.get_bonus_details[0].link }}
                                                                                    className="open-btn"
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    Open
                                                                                </Link>
                                                                            ) : null}

                                                                        </div>
                                                                    </div>

                                                                    <h3>{bonus?.get_bonus_details[0]?.question}</h3>

                                                                    <div className="ans-wrap">
                                                                        {bonus?.get_bonus_details[0]?.options && (
                                                                            JSON.parse(bonus.get_bonus_details[0].options || '[]').map((option, idx) => (
                                                                                <div key={idx} className="ans-wrap-single">
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => handleOptionClick(option)}
                                                                                        className={selectedOption === option ? 'selected' : ''}
                                                                                    />
                                                                                    <span>{option}</span>
                                                                                </div>
                                                                            ))
                                                                        )}
                                                                    </div>

                                                                    <div dangerouslySetInnerHTML={{ __html: bonus?.get_bonus_details[0]?.description }} />

                                                                    <div className="mt-4 text-center mb-3">
                                                                        <button
                                                                            className="submit-btn"
                                                                            type="submit"
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            {isSubmitting ? 'Sending...' : 'SEND'}
                                                                        </button>
                                                                    </div>

                                                                    <div className="btn-wrap">
                                                                        <p className="total-bonus">
                                                                            {user?.currency_sign}{bonus?.price ? parseFloat(bonus.price).toFixed(2) : '0.00'}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </form>
                                                    ) : bonus?.type === 2 ? (
                                                        <form onSubmit={(e) => handleBonus(e, bonus.id, bonus.slug, bonus.price, bonus.type)}>
                                                            <Col sm={12}>
                                                                <div className="bonus-form">
                                                                    <h3>{bonus?.title}</h3>
                                                                    <div dangerouslySetInnerHTML={{ __html: bonus?.description }} />

                                                                    <div className="text-left mb-3 div-wrap">
                                                                        {bonus?.get_bonus_details?.[0]?.link ? (
                                                                            <Link
                                                                                to={{ pathname: bonus.get_bonus_details[0].link }}
                                                                                className="open-btn"
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                Open
                                                                            </Link>
                                                                        ) : null}
                                                                        <input
                                                                            className="ss-upload"
                                                                            type="file"
                                                                            name="image"
                                                                            onChange={handleImageChange}
                                                                            required
                                                                        />
                                                                    </div>

                                                                    <div className="mt-4 text-center mb-3">
                                                                        <button
                                                                            className="submit-btn"
                                                                            type="submit"
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            {isSubmitting ? 'Sending...' : 'SEND'}
                                                                        </button>
                                                                    </div>

                                                                    <div className="btn-wrap">
                                                                        <p className="total-bonus">
                                                                            {user?.currency_sign}{bonus?.price ? parseFloat(bonus.price).toFixed(2) : '0.00'}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </form>
                                                    ) : null}
                                                </React.Fragment>
                                            ))
                                        ) : null}


                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            <DynamicHtmlRender htmlString={getAds?.sidebar_right} />
                        </Col>
                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>

    );
};

const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;
  color: #ffffff;
  
  .daily-task-title {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    display: inline-block;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 80px;
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .bonus-form {
    position: relative;
    background: #ffffff;
    border: 3px solid ${hover};
    border-radius: 5px;
    padding: 15px 15px 30px 15px;
    margin-bottom: 30px;

    h3, p, .ans-wrap {
      color: ${text};
      font-weight: 600;
      margin-bottom: 10px;
    }
    
    .div-wrap {
      display: flex;
      justify-content: center;
      padding: 15px 0 0 0;
    }

    .open-btn {
      background: ${hover};
      padding: 10px 30px;
      margin: 0 10px 0 0;
      border: 2px solid #F69647;
      border-radius: 25px;
      font-weight: 600;
      text-align: center;
      width: 35%;
    }
    
    .open-btn:hover {
      color: #ffffff !important;
    }
    
    .ss-upload {
      background: ${hover};
      padding: 10px 30px;
      margin: 0 0 0 10px;
      border: 2px solid #F69647;
      border-radius: 25px;
      font-weight: 600;
      position: relative;
      width: 35%;
      
      &:before {
        content: 'Upload';
        position: absolute;
        background: ${hover};
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        right: 0;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: ${text};
        cursor: pointer;
        font-weight: 600;
      }
    }

    .ss-upload:hover {
      &::before {
        color: #ffffff !important;
      }
    }

    .btn-wrap {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
    }

    .ans-wrap {
      display: flex;
      flex-direction: column;

      .ans-wrap-single {
        margin: 5px 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          font-weight: 600;
          font-size: 24px;
        }
      }

      button {
        background: #ffffff;
        border: 2px solid ${text};
        border-radius: 25px;
        padding: 0;
        margin: 0 10px 0 0;
        font-weight: 600;
        font-size: 24px;
        height: 40px;
        min-width: 40px;

        &.selected {
          background-color: #032873;
          border: 3px solid ${hover};
          color: #ffffff;
        }
      }
    }

    .submit-btn {
      padding: 10px 30px;
      margin: 0;
      border: 2px solid ${hover};
      border-radius: 10px;
      background: #ffffff;
      font-weight: 600;
      
      &:hover {
        color: #fff;
        background: ${hover};
      }
    }
    
    .total-bonus {
      padding: 10px 30px;
      margin: 0;
      border: 2px solid ${hover};
      border-radius: 25px;
      background: #ffffff;
      font-size: 30px;
      font-weight: 600;
    }
  }
`;

export default GetBonus;
