import React, {useState} from 'react';
import axios from '../../api/axios';
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Container, Row, Col} from "react-bootstrap";
import {hover, text, title} from '../../styles/globalStyleVars';
import MenuWithdraw from "../../components/MenuWithdraw";
import FloatMenu from "../../components/FloatMenu";
import useAuthContext from "../../context/AuthContext";
import {toast, ToastContainer} from "react-toastify";

const Withdraw = () => {
    const {user, csrf} = useAuthContext();
    const sanitizeValue = (value) => (value === "null" ? "" : value);
    const [selectedAmount, setSelectedAmount] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [withdrawView, setWithdrawView] = useState(0);
    const [withdrawText, setWithdrawText] = useState('');
    const [loading, setLoading] = useState(false);

    const amounts = [10, 20, 30, 50];

    const handlePaymentMethodSelect = (method, phone) => {
        setPaymentMethod(method);
        setPhoneNumber(sanitizeValue(phone));
        setWithdrawView(1);

        if (['bkash', 'nogod', 'paytm'].includes(method)) {
            setWithdrawText('Withdraw Number');
        } else if (['payoneer', 'paypal'].includes(method)) {
            setWithdrawText('Withdraw Email');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!paymentMethod) {
            toast.error('Please select a payment method.');
            return;
        }
        if (!selectedAmount) {
            toast.error('Please select amount.');
            return;
        }
        if (!phoneNumber) {
            toast.error('Please select a phone number.');
            return;
        }

        try {
            setLoading(true);

            const response = await axios.post('/api/withdraw', {
                amount: selectedAmount,
                transaction_through: paymentMethod,
                transaction_number: phoneNumber,
            });

            toast.success(response.data.message);
            setSelectedAmount('');
            setPhoneNumber('');
            setPaymentMethod('');
        } catch (error) {
            const errorMsg = error.response?.data?.message || 'Failed to process withdrawal';
            toast.error(errorMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Withdraw</title>
                <meta name="description" content="Withdraw"/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container style={{paddingBottom: '40px'}}>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                        <Col md={8} sm={12}>
                            <Row>
                                <FloatMenu />
                                <MenuWithdraw />

                                <Col sm={12}>
                                    <Row>
                                        {amounts.map((amount, index) => (
                                            <Col
                                                xs={6}
                                                key={amount}
                                                className={`amount-btn ${index % 2 === 0 ? 'amount-btn-left' : 'amount-btn-right'}`}
                                            >
                                                <button
                                                    className={`amount-button ${index % 2 === 0 ? 'amount-button-left' : 'amount-button-right'} ${selectedAmount === amount ? 'selected-val' : ''}`}
                                                    onClick={() => setSelectedAmount(amount)}
                                                >
                                                    {user?.currency_sign}{amount}
                                                </button>
                                            </Col>
                                        ))}

                                        <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                            <form onSubmit={handleSubmit}>
                                                <div className="text-left mb-3">
                                                    <label>Enter Amount</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={selectedAmount}
                                                        onChange={(e) => setSelectedAmount(e.target.value)}
                                                        placeholder={""}
                                                        required
                                                    />
                                                </div>

                                                <div className="text-left mb-3">
                                                    <label>Select Payment Gateway</label>
                                                    <Row>
                                                        <Col md={2} sm={4} xs={4} className="pay-wrap">
                                                            <button
                                                                type="button"
                                                                className={paymentMethod === 'bkash' ? 'selected-btn' : ''}
                                                                onClick={() => handlePaymentMethodSelect('bkash', user.bkash)}
                                                            >
                                                                <img
                                                                    className="pay-icon"
                                                                    src="/images/static/bkash.jpg"
                                                                    alt="bkash"
                                                                />
                                                            </button>
                                                        </Col>
                                                        <Col md={2} sm={4} xs={4} className="pay-wrap">
                                                            <button
                                                                type="button"
                                                                className={paymentMethod === 'nogod' ? 'selected-btn' : ''}
                                                                onClick={() => handlePaymentMethodSelect('nogod', user.nogod)}
                                                            >
                                                                <img
                                                                    className="pay-icon"
                                                                    src="/images/static/nogod.jpg"
                                                                    alt="nogod"
                                                                />
                                                            </button>
                                                        </Col>
                                                        <Col md={2} sm={4} xs={4} className="pay-wrap">
                                                            <button
                                                                type="button"
                                                                className={paymentMethod === 'paytm' ? 'selected-btn' : ''}
                                                                onClick={() => handlePaymentMethodSelect('paytm', user.paytm)}
                                                            >
                                                                <img
                                                                    className="pay-icon"
                                                                    src="/images/static/paytm.jpg"
                                                                    alt="paytm"
                                                                />
                                                            </button>
                                                        </Col>
                                                        <Col md={2} sm={4} xs={4} className="pay-wrap">
                                                            <button
                                                                type="button"
                                                                className={paymentMethod === 'payoneer' ? 'selected-btn' : ''}
                                                                onClick={() => handlePaymentMethodSelect('payoneer', user.payoneer)}
                                                            >
                                                                <img
                                                                    className="pay-icon"
                                                                    src="/images/static/payoneer.jpg"
                                                                    alt="payoneer"
                                                                />
                                                            </button>
                                                        </Col>
                                                        <Col md={2} sm={4} xs={4} className="pay-wrap">
                                                            <button
                                                                type="button"
                                                                className={paymentMethod === 'paypal' ? 'selected-btn' : ''}
                                                                onClick={() => handlePaymentMethodSelect('paypal', user.paypal)}
                                                            >
                                                                <img
                                                                    className="pay-icon"
                                                                    src="/images/static/paypal.jpg"
                                                                    alt="paypal"
                                                                />
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                {withdrawView && (
                                                    <div className="text-left mb-3">
                                                        <label>{withdrawText}</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={phoneNumber}
                                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                                            placeholder={withdrawText}
                                                            required
                                                        />
                                                    </div>
                                                )}

                                                <button
                                                    className="submit-btn"
                                                    type="submit"
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Processing...' : 'SUBMIT'}
                                                </button>
                                            </form>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>
                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>
    );
};


const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;
  
  .text-left {
    text-align: left;
  }
  
  .daily-task-title {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    display: inline-block;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 80px;
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
    }
  }
  
  label {
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 5px;
  }
  
  .amount-btn {
    
  }

  @media (min-width: 768px) {
    .amount-btn-left {
      padding-top: 15px;
      padding-bottom: 5px;
      padding-right: 0;
    }

    .amount-btn-right {
      padding-top: 15px;
      padding-bottom: 5px;
      padding-left: 0;
    }
  }

  @media (max-width: 767px) {
    .amount-btn-left {
      padding: 15px 0 5px 0;
    }

    .amount-btn-right {
      padding: 15px 0 5px 0;
    }
  }

  .pay-wrap {
    text-align: center;
    margin: 0 5px 5px;
    padding: 0;
    
    button {
      margin: 0;
      padding: 0;
      border-radius: 5px;
      overflow: hidden;
      
      &.selected-btn {
        border: 3px solid ${hover};
      }
    }
    .pay-icon {
      height: 55px;
    }
  }
  
  .form-control {
    font-weight: 600;
    border: 2px solid ${hover};

    &:hover {
      border: 2px solid ${hover};
    }

    &:focus {
      border: 2px solid ${hover};
    }
  }
  
  .submit-btn {
    padding: 10px 20px;
    border: 2px solid ${hover};
    border-radius: 10px;
    background: #ffffff;
    font-weight: 600;
  }
  
  .amount-button {
    width: 100%;
    display: inline-block;
    background-color: #FFFFFF;
    text-align: center;
    padding: 10px 40px;
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    transition: all .3s ease;
    
    &.amount-button-left {
      clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
    }
    
    &.amount-button-right {
      clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
    }

    &.selected-val {
      background: ${hover};
      color: #ffffff;
    }
  }

  .amount-button:hover {
    background-color: #F69647;
    color: #ffffff;
  }
`;

export default Withdraw;
