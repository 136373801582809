import React, {useEffect, useState} from 'react';
import axios from "../../api/axios";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Container, Row, Col} from "react-bootstrap";
import {hover, text, title} from '../../styles/globalStyleVars';
import {Link} from "react-router-dom";
import Menu from "../../components/Menu";
import FloatMenu from "../../components/FloatMenu";
import TaskButton from "../../components/TaskButton";
import CountdownTimer from "../../components/CountdownTimer";
import Pagination from "../../components/Pagination";
import Footer from "../../components/Footer";
import DynamicHtmlRender from "../../components/DynamicHtmlRender";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockClockIcon from '@mui/icons-material/LockClock';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ToastContainer, toast } from 'react-toastify';
import useAuthContext from "../../context/AuthContext";

const Home = () => {
    const [isTaskLoaded, setTaskIsLoaded] = useState(true);
    const [tasks, setTasks] = useState([]);
    const [getBonus, setGetBouns] = useState(null);
    const [getAds, setGetAds] = useState(null);
    const {user, csrf} = useAuthContext();
    const [pagination, setPagination] = useState({
        current_page: 1,
        last_page: 1,
        total: 0,
    });

    const fetchData = async (page = 1) => {
        try {
            setTaskIsLoaded(true);
            await csrf();
            const response = await axios.get('/api/tasks', {
                params: {page}
            });
            setTasks(response?.data?.tasks);
            setPagination({
                current_page: response?.data?.current_page,
                last_page: response?.data?.last_page,
                total: response?.data?.total,
            });
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching tasks.');
        } finally {
            setTaskIsLoaded(false);
        }
    }
    const fetchBonus = async () => {
        try {
            await csrf();
            const response = await axios.get('/api/get-bonus');
            if (response?.data?.success) {
                setGetBouns(1);
            } else {
                setGetBouns(null);
            }
        } catch (err) {
            console.error('Error fetching: ', err);
        }
    };

    const fetchAdsList = async (category = 'home') => {
        try {
            await csrf();
            const response = await axios.get('/api/ads-list', {
                params: {category}
            });
            console.log('ads', response?.data);
            setGetAds(response?.data);
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching ads.');
        }
    }

    useEffect(() => {
        fetchData();
        fetchBonus();
        fetchAdsList();
    }, []);

    const handlePageChange = (page) => {
        fetchData(page);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>SixtyFrog - Turn 60 Minutes into CASH.</title>
                <meta name="description" content="Learn about Cryptocurrency, Answer Questions and Earn for correct answer every 60 minutes a day. Start earning easily with SixtyFrog."/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            <DynamicHtmlRender htmlString={getAds?.home_sidebar_left} />
                        </Col>

                        <Col md={8} sm={12}>
                            <Row>
                                <FloatMenu />
                                <Menu />

                                <Col sm={12}>
                                    <Row>
                                        {getBonus && (
                                            <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                                <div className={'d-flex align-items-center justify-content-center'}>
                                                    <TaskButton
                                                        src={`/get-bonus`}
                                                        text={`Get Bonus`}
                                                        background={'#FFFFFF'}
                                                        color={'#000000'}
                                                        padding={'10px 60px'}
                                                    />

                                                    <img
                                                        style={{height: '50px', width: '50px', margin: '0 0 0 20px'}}
                                                        src={'/images/static/coin.png'} alt={''}
                                                    />
                                                </div>
                                            </Col>
                                        )}

                                        <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                            <p className={'daily-task-title'}>Daily Lesson</p>
                                        </Col>

                                        {isTaskLoaded ? (
                                            <>
                                                <Col xs={4}><div className="daily-task-card is-task-loaded"></div></Col>
                                                <Col xs={4}><div className="daily-task-card is-task-loaded"></div></Col>
                                                <Col xs={4}><div className="daily-task-card is-task-loaded"></div></Col>
                                            </>
                                        ) : (
                                            tasks?.length > 0 ? (
                                                <>
                                                    {tasks.map((task) => (
                                                        <Col xs={4} key={task?.id}>
                                                            {task.isUnlocked ? (
                                                                task.isCompleted === 1 ? (
                                                                    <div className="daily-task-card">
                                                                        <div className="icon-wrap">
                                                                            <CheckCircleOutlineIcon className="lock-icon" />
                                                                        </div>
                                                                        <div className="text-center">
                                                                            <p className="task-title">{task?.title}</p>
                                                                            <p>Completed</p>
                                                                        </div>
                                                                        <p className="task-bonus">
                                                                            {user?.currency_sign}{task?.price ? parseFloat(task.price).toFixed(2) : "0.00"}
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    new Date(task.unlockedAt) <= new Date() ? (
                                                                        <Link to={`/daily-task/${task?.slug}`} className="daily-task-card">
                                                                            <div className="icon-wrap">
                                                                                <LockOpenIcon className="lock-icon" />
                                                                            </div>
                                                                            <p className="task-title">{task?.title}</p>
                                                                            <p className="task-bonus">
                                                                                {user?.currency_sign}{task?.price ? parseFloat(task.price).toFixed(2) : "0.00"}
                                                                            </p>
                                                                        </Link>
                                                                    ) : (
                                                                        <div className="daily-task-card">
                                                                            <div className="icon-wrap">
                                                                                <LockClockIcon className="lock-icon" />
                                                                            </div>
                                                                            <div className="text-center">
                                                                                <p className="task-title">{task?.title}</p>
                                                                                <span>
                                                                                <CountdownTimer unlockTime={task.unlockedAt} />
                                                                            </span>
                                                                            </div>
                                                                            <p className="task-bonus">
                                                                                {user?.currency_sign}{task?.price ? parseFloat(task.price).toFixed(2) : "0.00"}
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                )
                                                            ) : (
                                                                <div className="daily-task-card">
                                                                    <div className="icon-wrap">
                                                                        <LockIcon className="lock-icon" />
                                                                    </div>
                                                                    <p className="task-title">{task?.title}</p>
                                                                    <p className="task-bonus">
                                                                        {user?.currency_sign}{task?.price ? parseFloat(task.price).toFixed(2) : "0.00"}
                                                                    </p>
                                                                </div>
                                                            )}

                                                        </Col>
                                                    ))}

                                                    <Pagination
                                                        currentPage={pagination.current_page}
                                                        totalPages={pagination.last_page}
                                                        onPageChange={handlePageChange}
                                                    />
                                                </>
                                            ) : (
                                                <Col sm={12}>
                                                    <div className="daily-task-card">
                                                        <p>No items available.</p>
                                                    </div>
                                                </Col>
                                            )
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            <DynamicHtmlRender htmlString={getAds?.home_sidebar_right} />
                        </Col>
                    </Row>
                    <Footer data={user} />
                </Container>
            </StyledSection>
        </HelmetProvider>
    );
};


const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;

  .is-task-loaded {
    opacity: 20%;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-name: change-color;
  }

  @keyframes change-color {
    from {
      background-color: #ffffff;
    }
    to {
      background-color: #ddb787;
    }
  }

  .result-wrap {
    background: ${hover};
    padding-top: 10px;
    padding-bottom: 10px;

    .item-card {
      p {
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .daily-task-title {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    display: inline-block;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 80px;
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .daily-task-card {
    background: white;
    padding: 10px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    min-height: 75px;
    position: relative;

    &.withdraw {
      background: #9797a5;
    }

    .notify {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 50%;
    }

    @media (max-width: 991px) {
      flex-direction: column;
      justify-content: center;
      height: 100px;
      margin: 10px 0 30px 0;

      .icon-wrap {
        position: absolute;
        top: 0;
        left: 0;
      }

      .task-bonus {
        background: #F69647;
        color: #fff;
        position: absolute;
        bottom: -20px;
        font-weight: 600;
        padding: 5px 15px;
        border-radius: 15px;
        z-index: 1;;
      }
    }
  }

  .task-title {
    font-weight: 600;
  }

  .lock-icon {
    height: 30px;
    width: 30px;
    color: ${hover};
  }
`;

export default Home;
