import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {hover, text} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import HomeIcon from "@mui/icons-material/Home";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import useAuthContext from '../context/AuthContext';
import React from "react";

const FloatMenu = () => {
    const {user, logout} = useAuthContext();
    return (
        <StyledComponent>
            <Container className={'float-menu'}>
                <Row className={'bg-white'}>
                    <Col className={'logo-wrap'}>
                        <img className={'logo'} src="/images/logo.png" alt="logo"/>
                    </Col>
                    <Col className={'menu'}>
                        <div className={'float-logo-wrap'}>
                            <Link to="/home">
                                <HomeIcon className={'float-logo'} />
                            </Link>
                        </div>
                        {user && (
                            <>
                                <div className={'float-logo-wrap'}>
                                    <Link to="/withdraw">
                                        <AccountBalanceWalletIcon className={'float-logo'} />
                                    </Link>
                                </div>
                                <div className={'float-logo-wrap'}>
                                    <Link to="/withdraw-history">
                                        <WorkHistoryIcon className={'float-logo'} />
                                    </Link>
                                </div>
                                <div className={'float-logo-wrap'}>
                                    <button onClick={logout}>
                                        <PowerSettingsNewIcon className={'float-logo'} />
                                    </button>
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  z-index: 10;
  
  @media (max-width: 767px) {
    padding: 0;
  }

  .float-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: ${text};
    margin: 0;
    min-width: 100%;

    .logo-wrap {
      display: none;

      .logo {
        height: 50px;
      }
    }

    .menu {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      //background-color: white;
    }
    
    @media (min-width: 768px) {
      position: unset;
      border-bottom: 1px solid ${text};

      .logo-wrap {
        display: unset;
      }

      .menu {
        justify-content: flex-end;
      }

      .float-logo-wrap {
        margin-left: 10px;
      }
    }

    .float-logo-wrap {
        a, button {
          display: flex;
          background: transparent;
          border: 0;
          height: 40px;
          width: 40px;
          padding: 0;
        }
    }
    
    .float-logo {
      height: 30px;
      width: 30px;
      color: ${text};
      margin: 5px;
      transition: color .3s ease;
      
      &:hover {
        color: ${hover};
      }
    }
  }
`;

export default FloatMenu;
