import React, {useState} from 'react';
import axios from "../../api/axios";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Container, Row, Col} from "react-bootstrap";
import {hover, text, title} from '../../styles/globalStyleVars';
import LockIcon from "@mui/icons-material/Lock";
import { ToastContainer, toast } from 'react-toastify';
import useAuthContext from "../../context/AuthContext";
import FloatMenu from "../../components/FloatMenu";


const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { csrf } = useAuthContext();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            toast.error("New password and confirm password do not match");
            return;
        }

        setIsSubmitting(true);

        try {
            await csrf();
            const response = await axios.post('/api/change-password', {
                current_password: currentPassword,
                new_password: newPassword,
                new_password_confirmation: confirmPassword,
            });

            toast.success(response.data.message || "Password changed successfully");
            resetForm();
        } catch (error) {
            handleErrors(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleErrors = (error) => {
        const message = error.response?.data?.message || "Something went wrong";
        toast.error(message);
    };

    const resetForm = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
    };

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Change Password</title>
                <meta name="description" content="Change Password"/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                        <Col md={8} sm={12}>
                            <FloatMenu />

                            <Row className={'mt-3'}>

                                <Col sm={12} className={'text-center'} style={{padding: '10px 10px 60px 10px'}}>
                                    <p className={'task-title'}>Change <br/> Password</p>
                                </Col>

                                <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                    <form onSubmit={handleSubmit}>
                                        <div className="input-container">
                                            <LockIcon className={'icon'} />
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Current Password"
                                                value={currentPassword}
                                                onChange={(e) => setCurrentPassword(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="input-container">
                                            <LockIcon className={'icon'} />
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder="New Password"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="input-container">
                                            <LockIcon className={'icon'} />
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Confirm Password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="mb-3 mt-4">
                                            <button
                                                className="submit-btn"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? 'Submitting...' : 'SAVE'}
                                            </button>
                                        </div>

                                    </form>
                                </Col>

                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>
    );
};


const StyledSection = styled.section`
  min-height: 100vh;

  .task-title {
    font-size: 50px;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    line-height: normal;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .input-container {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
  }

  .icon {
    padding: 10px;
    background-color: transparent;
    min-width: 50px;
    text-align: center;
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 1;
    height: calc(100% - 4px);
  }

  .form-control {
    width: 100%;
    padding: 10px 10px 10px 60px;
    outline: none;
    font-weight: 600;
    border: 2px solid ${hover};
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 15px;

    &:hover {
      border: 2px solid ${hover};
    }

    &:focus {
      border: 2px solid ${hover};
    }
  }

  .forget-password {
    display: flex;

    a {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .sign-up {
    display: flex;

    p {
      font-weight: 600;
      font-style: italic;
    }

    a {
      color: #ad0a87;
      font-weight: 600;
    }
  }

  .terms_condition_group {
    display: flex;

    input {
      height: 24px;
      width: 24px;
    }
  }

  .submit-btn {
    padding: 10px 20px;
    border: 2px solid ${hover};
    border-radius: 10px;
    background: #ffffff;
    font-weight: 600;

    &.reset-btn {
      padding: 0 20px;
    }
  }

`;

export default ChangePassword;
