import React, {useEffect, useState} from 'react';
import axios from "../../api/axios";
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async';
import {Container, Row, Col} from "react-bootstrap";
import {hover, text} from "../../styles/globalStyleVars";
import {useParams} from "react-router-dom";
import FloatMenu from "../../components/FloatMenu";
import DynamicHtmlRender from "../../components/DynamicHtmlRender";
import {Loading} from "../../components/Loading";
import { ToastContainer, toast } from 'react-toastify';
import useAuthContext from "../../context/AuthContext";

const DailyTaskDetails = () => {
    const { task, slug } = useParams();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [getAds, setGetAds] = useState(null);
    const {csrf} = useAuthContext();

    const fetchData = async () => {
        setLoading(true);
        try {
            await csrf();
            const response = await axios.get(`/api/items-details/${task}/${slug}`);
            setItems(response?.data?.data || {});
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching task details.');
        } finally {
            setLoading(false);
        }
    };

    const fetchAdsList = async (category = 'item_details') => {
        try {
            await csrf();
            const response = await axios.get('/api/ads-list', {
                params: {category}
            });
            setGetAds(response?.data);
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching ads.');
        }
    }

    useEffect(() => {
        fetchData();
        fetchAdsList();
    }, [task, slug]);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedOption) {
            toast.warn('Please select an option.');
            return;
        }

        setIsSubmitting(true);

        try {
            await csrf();
            const response = await axios.post(`/api/items/${items.id}/submit`, {
                question_id: items?.questions[0]?.id,
                answer: selectedOption,
            });

            toast.success(response.data.message || 'Answer submitted successfully!');
        } catch (err) {
            console.error('Error submitting answer:', err);

            if (err.response && err.response.status === 422) {
                const errors = err.response.data.errors;
                Object.keys(errors).forEach((field) =>
                    errors[field].forEach((message) => toast.error(message))
                );
            } else {
                toast.error(err?.response?.data?.message || 'An unexpected error occurred.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Sixty Frog</title>
                <meta name="description" content="Sixty Frog" />
            </Helmet>

            <ToastContainer />

            <StyledSection>
                {loading ?
                    <Loading />
                :
                    <Container style={{paddingBottom: '40px'}}>
                        <Row>
                            <Col md={2} sm={0} className={'ads'}>
                                <DynamicHtmlRender htmlString={getAds?.home_sidebar_left} />
                            </Col>

                            <Col md={8} sm={12}>
                                <Row>
                                    <FloatMenu />

                                    <Col sm={12}>
                                        <Row className={'mt-3'}>
                                            <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                                <p className={'daily-task-title'}>Lesson Details</p>
                                            </Col>

                                            <Col sm={12}>
                                                <h1>{items?.short_title}</h1>
                                                <div dangerouslySetInnerHTML={{ __html: items?.short_desc }} />

                                                {items?.questions?.length > 0 && (
                                                    <div className={'query-form'}>
                                                        <h3>{items?.questions[0]?.question}</h3>

                                                        <form onSubmit={handleSubmit}>
                                                            <div className={'ans-wrap'}>
                                                                {JSON.parse(items?.questions[0]?.options).map((option, index) => (
                                                                    <div className={'ans-wrap-single'} key={index}>
                                                                        <button
                                                                            key={index}
                                                                            type="button"
                                                                            onClick={() => handleOptionClick(option)}
                                                                            className={selectedOption === option ? 'selected' : ''}
                                                                        >
                                                                        </button>
                                                                        <span>{option}</span>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                            <div dangerouslySetInnerHTML={{ __html: items?.questions[0]?.description }} />

                                                            <div className="btn-wrap">
                                                                <button
                                                                    className={'submit-btn'}
                                                                    type="submit"
                                                                    disabled={isSubmitting}
                                                                >
                                                                    {isSubmitting ? 'Submitting...' : 'SUBMIT'}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                )}

                                                <div dangerouslySetInnerHTML={{ __html: items?.description }} />
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={2} sm={0} className={'ads'}>
                                <DynamicHtmlRender htmlString={getAds?.home_sidebar_right} />
                            </Col>
                        </Row>
                    </Container>
                }
            </StyledSection>
        </HelmetProvider>

    );
};

const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;
  color: #ffffff;

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, b, ol, ul, li, p, span {
    color: #ffffff !important;
  }
  
  .daily-task-title {
    font-size: 24px;
    font-weight: 600;
    color: #000000 !important;
    display: inline-block;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 80px;
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
    }
  }


  h1, p {
    padding-bottom: 15px;
  }

  h3 {
    font-weight: 600;
  }

  .query-form {
    position: relative;
    border: 5px solid ${hover};
    background: #ffffff;
    border-radius: 5px;
    padding: 30px 15px;
    margin-bottom: 30px;

    h3, p, .ans-wrap {
      color: ${text};
      font-weight: 600;
      margin-bottom: 10px;
    }

    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, b, ol, ul, li, p, span {
      color: ${text} !important;
    }

    .ans-wrap {
      display: flex;
      flex-direction: column;
      
      .ans-wrap-single {
        margin: 5px 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          font-weight: 600;
          font-size: 24px;
        }
      }
      
      button {
        background: #ffffff;
        border: 2px solid ${text};
        border-radius: 25px;
        padding: 0;
        margin: 0 10px 0 0;
        font-weight: 600;
        font-size: 24px;
        height: 40px;
        min-width: 40px;
        
        &.selected {
          background-color: #032873;
          border: 3px solid ${hover};
          color: #ffffff;
        }
      }
    }

    .btn-wrap {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
    }

    .submit-btn {
      padding: 10px 20px;
      border: 3px solid ${hover};
      border-radius: 10px;
      background: #ffffff;
      font-weight: 600;
    }
  }
`;

export default DailyTaskDetails;
