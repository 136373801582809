import React from 'react';
import styled from 'styled-components';
import {hover} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";


const TaskButton = ({
                    text,
                    src,
                    width,
                    fontSize,
                    fontWeight,
                    color,
                    hoverColor,
                    background,
                    hoverBackground,
                    padding,
                    polygon,
                }) => {
    return (
        <StyledBtn
            text={text}
            src={src}
            width={width}
            fontSize={fontSize}
            fontWeight={fontWeight}
            color={color}
            hoverColor={hoverColor}
            background={background}
            hoverBackground={hoverBackground}
            padding={padding}
            polygon={polygon}
        >
            <Link to={src || '/home'} className={`task-button`}>
                <span>{text}</span>
            </Link>
        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  .task-button {
    width: ${props => props.width || 'unset'};
    display: inline-block;
    background-color: ${props => props.background || hover};
    text-align: center;
    padding: ${props => props.padding || '15px 80px'};
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    clip-path: ${props => props.polygon || 'polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%)'};
    transition: background-color 0.3s ease;

    span {
      font-size: ${props => props.fontSize || '24'}px;
      font-weight: ${props => props.fontWeight || 600};
      color: ${props => props.color || `#FFF`};
      transition: color .3s ease;
    }
  }

  .task-button:hover {
    background-color: ${props => props.hoverBackground || '#F69647'};
    
    span {
      color: ${props => props.hoverColor || `#FFF`};
    }
  }
`;


export default TaskButton;
