import React, {useState} from 'react';
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Container, Row, Col} from "react-bootstrap";
import {hover, text, title} from '../../styles/globalStyleVars';
import {useHistory} from "react-router-dom";
import FloatMenu from "../../components/FloatMenu";
import { toast, ToastContainer } from "react-toastify";
import useAuthContext from "../../context/AuthContext";
import axios from "../../api/axios";

const Support = () => {
    const {user, csrf} = useAuthContext();
    const [name, setName] = useState(user?.name);
    const [email, setEmail] = useState(user?.email);
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();

    const handleSupport = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            await csrf();
            const formData = new FormData();
            formData.append('name', name);
            formData.append('phone', phone);
            formData.append('email', email);
            formData.append('message', message);

            const response = await axios.post('/api/support/submit', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            toast.success(
                <>
                    Message sent successfully.
                    <br />
                    We will contact you soon!
                </>
            );
            // history.push('/home');
        } catch (err) {
            if (err.response && err.response.status === 422) {
                const errors = err.response.data.errors;
                Object.keys(errors).forEach((field) => {
                    errors[field].forEach((msg) => {
                        toast.error(msg);
                    });
                });
            } else {
                console.error('Error fetching:', err);
                toast.error('An unexpected error occurred.');
            }
        } finally {
            setIsSubmitting(false);
        }

    };
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Support</title>
                <meta name="description" content="Support"/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container style={{paddingBottom: '40px'}}>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                        <Col md={8} sm={12}>
                            <Row>
                                <FloatMenu />

                                <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                    <p className={'daily-task-title'}>Support</p>
                                </Col>

                                <Col sm={12} className={'profile'}>
                                    <div>
                                        <img
                                            src={user?.image ? `${process.env.REACT_APP_API_URL}/storage/${user?.image}` : '/images/static/avater.jpg'}
                                            alt="profile-picture"
                                        />
                                    </div>

                                    <div>
                                        <h4>{user?.name}</h4>
                                        <p>{user?.email}</p>
                                        <p>ID: {user.user_id}</p>
                                    </div>
                                </Col>


                                <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                    <form onSubmit={handleSupport}>
                                        <div className="text-left mb-3">
                                            <label>Full name</label>
                                            <input className={'form-control'} type={'text'} name={'name'} value={user?.name} required readOnly />
                                        </div>

                                        <div className="text-left mb-3">
                                            <label>Phone</label>
                                            <input
                                                className={'form-control'}
                                                type={'phone'}
                                                name={'phone'}
                                                placeholder={'01XXXXXXXXX'}
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="text-left mb-3">
                                            <label>Email</label>
                                            <input
                                                className={'form-control'}
                                                type={'email'}
                                                name={'email'}
                                                placeholder={'example@gmail.com'}
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="text-left mb-3">
                                            <label>Message</label>
                                            <textarea
                                                className={'form-control'}
                                                name={'message'}
                                                cols="30"
                                                rows="10"
                                                placeholder={'Your message here...'}
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                required
                                            ></textarea>
                                        </div>

                                        <div className="mb-3 mt-4">
                                            <button
                                                className={'submit-btn'}
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? 'Sending...' : 'SUBMIT'}
                                            </button>
                                        </div>

                                    </form>
                                </Col>

                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>
    );
};


const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;
  
  .text-left {
    text-align: left;
  }
  
  .profile {
    background-color: ${hover};
    display: flex;
    align-items: center;
    padding: 10px;

    @media (max-width: 767px) {
      align-items: flex-start;
    }

    h4, p {
      font-weight: 600;
    }

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
      overflow: hidden;
      margin: 0 20px 0 0;

      @media (max-width: 767px) {
        height: 60px;
        width: 60px;
        margin: 0 10px 0 0;
      }
    }
  }
  
  .daily-task-title {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    display: inline-block;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 80px;
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
    }
  }
  
  label {
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 5px;
  }
  
  .form-control {
    font-weight: 600;
    border: 2px solid ${hover};

    &:hover {
      border: 2px solid ${hover};
    }

    &:focus {
      border: 2px solid ${hover};
    }
  }
  
  .submit-btn {
    padding: 10px 20px;
    border: 2px solid ${hover};
    border-radius: 10px;
    background: #ffffff;
    font-weight: 600;
    
    &.reset-btn {
      padding: 0 20px;
    }
  }
`;

export default Support;
