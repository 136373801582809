import React, {useEffect, useState} from 'react';
import axios from "../../api/axios";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Container, Row, Col} from "react-bootstrap";
import {hover, text, title} from '../../styles/globalStyleVars';
import {Link, useParams} from "react-router-dom";
import Menu from "../../components/Menu";
import FloatMenu from "../../components/FloatMenu";
import Pagination from "../../components/Pagination";
import DynamicHtmlRender from "../../components/DynamicHtmlRender";
import useAuthContext from "../../context/AuthContext";
import { ToastContainer, toast } from 'react-toastify';

const DailyTask = () => {
    const { slug } = useParams();
    const [items, setItems] = useState([]);
    const [summary, setSummary] = useState([]);
    const [isTaskLoaded, setTaskIsLoaded] = useState(true);
    const [getAds, setGetAds] = useState(null);
    const {user, csrf} = useAuthContext();
    const [pagination, setPagination] = useState({
        current_page: 1,
        last_page: 1,
        total: 0,
    });

    const fetchData = async (page = 1) => {
        try {
            setTaskIsLoaded(true);
            await csrf();
            const response = await axios.get(`/api/tasks/${slug}`, {
                params: {page}
            });
            setSummary(response?.data?.data?.summary);
            const items = Array.isArray(response.data.data.taskItems)
                ? response.data.data.taskItems
                : Object.values(response.data.data.taskItems);
            setItems(items);
            setPagination({
                current_page: response?.data?.data?.pagination?.current_page || 1,
                last_page: response?.data?.data?.pagination?.last_page || 1,
                total: response?.data?.data?.pagination?.total || 0,
            });
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching task items.');
        } finally {
            setTaskIsLoaded(false);
        }
    };

    const fetchAdsList = async (category = 'item') => {
        try {
            await csrf();
            const response = await axios.get('/api/ads-list', {
                params: {category}
            });
            setGetAds(response?.data);
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching ads.');
        }
    }

    useEffect(() => {
        fetchData();
        fetchAdsList();
    }, []);

    const handlePageChange = (page) => {
        fetchData(page);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Sixty Frog</title>
                <meta name="description" content="Sixty Frog" />
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container style={{paddingBottom: '40px'}}>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            <DynamicHtmlRender htmlString={getAds?.home_sidebar_left} />
                        </Col>

                        <Col md={8} sm={12}>
                            <Row>
                                <FloatMenu />
                                <Menu />

                                <Col sm={12} className={'text-center mt-4'}>
                                    <Row className={'result-wrap m-0'}>
                                        <Col xs={12} className={'item-card'}>
                                            <p>Total Task: {summary?.completedCount}/{summary?.totalItems}</p>
                                        </Col>

                                        <Col xs={6} className={'item-card'}>
                                            <p>Right: {summary?.correctCount}</p>
                                        </Col>

                                        <Col xs={6} className={'item-card'}>
                                            <p>Wrong: {summary?.incorrectCount}</p>
                                        </Col>

                                        <Col xs={6} className={'item-card'}>
                                            <p>Earn: {user?.currency_sign}{summary?.totalEarned}</p>
                                        </Col>

                                        <Col xs={6} className={'item-card'}>
                                            <p>Loss: {user?.currency_sign}{summary?.totalLost}</p>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col sm={12}>
                                    <Row className={'mt-3'}>
                                        {isTaskLoaded ? (
                                            <>
                                                <Col md={4} sm={12}><div className="daily-task-card is-task-loaded"></div></Col>
                                                <Col md={4} sm={12}><div className="daily-task-card is-task-loaded"></div></Col>
                                                <Col md={4} sm={12}><div className="daily-task-card is-task-loaded"></div></Col>
                                            </>
                                        ) : (
                                            items?.length > 0 ? (
                                                <>
                                                    {items.map((item) => (
                                                        <Col md={4} sm={12} key={item?.id}>
                                                            {item.isUnlocked ? (
                                                                item.isAnswered ? (
                                                                    <div className={'daily-task-card'}>
                                                                        <div className={'icon-wrap'}>
                                                                            <img
                                                                                className={'lock-icon'}
                                                                                src={item?.image ? `${process.env.REACT_APP_API_URL}/storage/${item.image}` : '/images/static/blur.jpg'}
                                                                                alt="lesson-logo"
                                                                            />
                                                                            {/*<CheckCircleOutlineIcon className={'lock-icon'} />*/}
                                                                        </div>
                                                                        <p className={'task-title'}>{item?.title}</p>
                                                                    </div>
                                                                ) : (
                                                                    <Link to={`/daily-task/${slug}/${item?.slug}`} className={'daily-task-card'}>
                                                                        <div className={'icon-wrap'}>
                                                                            <img
                                                                                className={'lock-icon'}
                                                                                src={item?.image ? `${process.env.REACT_APP_API_URL}/storage/${item.image}` : '/images/static/blur.jpg'}
                                                                                alt="lesson-logo"
                                                                            />
                                                                            {/*<LockOpenIcon className={'lock-icon'} />*/}
                                                                        </div>
                                                                        <p className={'task-title'}>{item?.title}</p>
                                                                    </Link>
                                                                )
                                                            ) : (
                                                                <div className={'daily-task-card active'}>
                                                                    <div className={'icon-wrap'}>
                                                                        {/*<LockIcon className={'lock-icon'} />*/}
                                                                        <img
                                                                            className={'lock-icon'}
                                                                            src={item?.image ? `${process.env.REACT_APP_API_URL}/storage/${item.image}` : '/images/static/blur.jpg'}
                                                                            alt="lesson-logo"
                                                                        />
                                                                    </div>
                                                                    <p className={'task-title'}>{item?.title}</p>
                                                                </div>
                                                            )}
                                                        </Col>
                                                    ))}

                                                    <Pagination
                                                        currentPage={pagination.current_page}
                                                        totalPages={pagination.last_page}
                                                        onPageChange={handlePageChange}
                                                    />
                                                </>
                                            ) : (
                                                <Col sm={12}>
                                                    <div className={'daily-task-card'}>
                                                        <p>No items available.</p>
                                                    </div>
                                                </Col>
                                            )
                                        )}

                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            <DynamicHtmlRender htmlString={getAds?.home_sidebar_right} />
                        </Col>
                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>
    );
};


const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;

  .is-task-loaded {
    opacity: 20%;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-name: change-color;
  }

  @keyframes change-color {
    from {
      background-color: #ffffff;
    }
    to {
      background-color: #ddb787;
    }
  }

  .result-wrap {
    background: ${hover};
    padding-top: 10px;
    padding-bottom: 10px;

    .item-card {
      p {
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .daily-task-card {
    background: white;
    padding: 10px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    min-height: 75px;
    position: relative;
    border: 3px solid ${hover};
    
    &.active {
      background: #9797a5;
    }
    
    .icon-wrap {
      margin-right: 20px;
    }
  }

  .task-title {
    font-weight: 600;
  }

  .lock-icon {
    height: 40px;
    width: 40px;
    color: ${hover};
  }
`;

export default DailyTask;
