import {useState} from 'react';
import axios from "../../api/axios";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Container, Row, Col} from "react-bootstrap";
import {hover, text, title} from '../../styles/globalStyleVars';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { toast, ToastContainer } from 'react-toastify';
import useAuthContext from "../../context/AuthContext";
import {Loading} from "../../components/Loading";


const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {csrf} = useAuthContext();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        await csrf();

        try{
            const response = await axios.post('/forgot-password', {email});
            toast.success('Password reset link sent to your email!');
        } catch (error) {
            handleErrors(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleErrors = (error) => {
        if (error?.response?.status === 422) {
            const validationErrors = error.response.data.errors;
            Object.values(validationErrors).forEach((messages) =>
                messages.forEach((msg) => toast.error(msg))
            );
        } else {
            console.error('Unexpected error:', error);
            toast.error('Something went wrong. Please try again.');
        }
    };

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Forget Password</title>
                <meta name="description" content="Forget Password"/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                        <Col md={8} sm={12}>
                            <Row className={'mt-3'}>

                                <Col sm={12} className={'text-center'} style={{padding: '10px 10px 60px 10px'}}>
                                    <p className={'task-title'}>Forget <br/> Password</p>
                                </Col>

                                <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                    {isSubmitting ? (
                                        <Loading />
                                    ) : (
                                        <form onSubmit={handleSubmit}>
                                            <div className="input-container">
                                                <MailOutlineIcon className={'icon'} />
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="mb-3 mt-4">
                                                <button
                                                    className={'submit-btn'}
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? 'Sending...' : 'SEND'}
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Col>

                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>
    );
};


const StyledSection = styled.section`
  min-height: 100vh;

  .task-title {
    font-size: 50px;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    line-height: normal;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .input-container {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
  }

  .icon {
    padding: 10px;
    background-color: transparent;
    min-width: 50px;
    text-align: center;
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 1;
    height: calc(100% - 4px);
  }

  .form-control {
    width: 100%;
    padding: 10px 10px 10px 60px;
    outline: none;
    font-weight: 600;
    border: 2px solid ${hover};
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 15px;

    &:hover {
      border: 2px solid ${hover};
    }

    &:focus {
      border: 2px solid ${hover};
    }
  }

  .forget-password {
    display: flex;

    a {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .sign-up {
    display: flex;

    p {
      font-weight: 600;
      font-style: italic;
    }

    a {
      color: #ad0a87;
      font-weight: 600;
    }
  }

  .terms_condition_group {
    display: flex;

    input {
      height: 24px;
      width: 24px;
    }
  }

  .submit-btn {
    padding: 10px 20px;
    border: 2px solid ${hover};
    border-radius: 10px;
    background: #ffffff;
    font-weight: 600;

    &.reset-btn {
      padding: 0 20px;
    }
  }

`;

export default ForgetPassword;
