import React, {useEffect, useState} from 'react';
import axios from "../../api/axios";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Container, Row, Col} from "react-bootstrap";
import {hover, text, title} from '../../styles/globalStyleVars';
import FloatMenu from "../../components/FloatMenu";
import useAuthContext from "../../context/AuthContext";
import {toast, ToastContainer} from "react-toastify";

const WithdrawHistory = () => {
    const [withdraws, setWithdraws] = useState([]);
    const {user, csrf} = useAuthContext();

    const fetchData = async () => {
        try {
            await csrf();
            const response = await axios.get(`/api/withdraw-history/${user?.id}`);
            if (!response?.data?.success) {
                toast.error(response?.data?.message || 'No data Found.');
            }
            setWithdraws(response?.data?.data || []);
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching get bonus.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Withdraw History</title>
                <meta name="description" content="Withdraw History"/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container style={{paddingBottom: '40px'}}>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                        <Col md={8} sm={12}>
                            <Row>
                                <FloatMenu />

                                <Col sm={12}>
                                    <Row className={'mt-3'}>
                                        <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                            <p className={'daily-task-title'}>Withdraw History</p>
                                        </Col>

                                        {withdraws.length > 0 ? (
                                            withdraws.map((withdraw) => (
                                                <Col md={4} sm={12} key={withdraw.id}>
                                                    <div className="daily-task-card">
                                                        <p className="date">
                                                            {new Date(withdraw.created_at).toLocaleDateString()}
                                                        </p>
                                                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                                                            <div className="d-flex flex-column">
                                                                <p>Amount</p>
                                                                <p>{user?.currency_sign}{withdraw.amount.toFixed(2)}</p>
                                                            </div>
                                                            <div className="icon-wrap">
                                                                <img
                                                                    className="pay-icon"
                                                                    src={`/images/static/${withdraw.transaction_through}.jpg`}
                                                                    alt={withdraw.transaction_through}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        ) : (
                                            <Col sm={12} className="text-center">
                                                <p className="text-white">No withdraw history found.</p>
                                            </Col>
                                        )}

                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>
                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>
    );
};


const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;
  
  .daily-task-title {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    display: inline-block;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 80px;
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .daily-task-card {
    background: white;
    padding: 10px;
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    
    p {
      font-size: 20px;
      font-weight: 600;
    }
    
    .date {
      margin-bottom: 15px;
    }
  }

  .pay-icon {
    height: 45px;
    border-radius: 5px;
    border: 2px solid ${text};
  }
`;

export default WithdrawHistory;
