import React, {useEffect, useState} from 'react';
import axios from "../../api/axios";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Container, Row, Col} from "react-bootstrap";
import {hover, text, title} from '../../styles/globalStyleVars';
import {Link, useParams} from "react-router-dom";
import FloatMenu from "../../components/FloatMenu";
import useAuthContext from "../../context/AuthContext";
import { ToastContainer, toast } from 'react-toastify';

const DailyTask = () => {
    const { slug } = useParams();
    const [items, setItems] = useState([]);
    const [isTaskLoaded, setTaskIsLoaded] = useState(true);
    const {csrf} = useAuthContext();

    const fetchData = async () => {
        try {
            setTaskIsLoaded(true);
            await csrf();
            const response = await axios.get(`/api/tasks-check/${slug}`);
            setItems(response.data.data.taskItems);
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching task items.');
        } finally {
            setTaskIsLoaded(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Sixty Frog</title>
                <meta name="description" content="Sixty Frog" />
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container style={{paddingBottom: '40px'}}>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                        <Col md={8} sm={12}>
                            <Row>
                                <FloatMenu />

                                <Col sm={12}>
                                    <Row className={'mt-3'}>
                                        {isTaskLoaded ? (
                                            <>
                                                <Col md={4} sm={12}><div className="daily-task-card is-task-loaded"></div></Col>
                                                <Col md={4} sm={12}><div className="daily-task-card is-task-loaded"></div></Col>
                                                <Col md={4} sm={12}><div className="daily-task-card is-task-loaded"></div></Col>
                                            </>
                                        ) : (
                                            items?.length > 0 ? (
                                                <>
                                                    {items.map((item) => (
                                                        <Col md={4} sm={12} key={item?.id}>
                                                            <Link to={`/items-details-check/${slug}/${item?.slug}`} className={'daily-task-card'}>
                                                                <div className={'icon-wrap'}>
                                                                    <img
                                                                        className={'lock-icon'}
                                                                        src={item?.image ? `${process.env.REACT_APP_API_URL}/storage/${item.image}` : '/images/static/blur.jpg'}
                                                                        alt="lesson-logo"
                                                                    />
                                                                </div>
                                                                <p className={'task-title'}>{item?.title}</p>
                                                            </Link>
                                                        </Col>
                                                    ))}
                                                </>
                                            ) : (
                                                <Col sm={12}>
                                                    <div className={'daily-task-card'}>
                                                        <p>No items available.</p>
                                                    </div>
                                                </Col>
                                            )
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>
                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>
    );
};


const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;

  .is-task-loaded {
    opacity: 20%;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-name: change-color;
  }

  @keyframes change-color {
    from {
      background-color: #ffffff;
    }
    to {
      background-color: #ddb787;
    }
  }

  .result-wrap {
    background: ${hover};
    padding-top: 10px;
    padding-bottom: 10px;

    .item-card {
      p {
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .daily-task-card {
    background: white;
    padding: 10px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    min-height: 75px;
    position: relative;
    border: 3px solid ${hover};
    
    &.active {
      background: #9797a5;
    }
    
    .icon-wrap {
      margin-right: 20px;
    }
  }

  .task-title {
    font-weight: 600;
  }

  .lock-icon {
    height: 40px;
    width: 40px;
    color: ${hover};
  }
`;

export default DailyTask;
