import React, {useEffect, useState} from 'react';
import axios from "../../api/axios";
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async';
import {Container, Row, Col} from "react-bootstrap";
import {hover, text} from "../../styles/globalStyleVars";
import {Link} from "react-router-dom";
import FloatMenu from "../../components/FloatMenu";
import Pagination from "../../components/Pagination";
import DynamicHtmlRender from "../../components/DynamicHtmlRender";
import {toast, ToastContainer} from "react-toastify";
import useAuthContext from "../../context/AuthContext";

const Blog = () => {
    const [blogs, setBlogs] = useState([]);
    const [latestBlogs, setLatestBlogs] = useState([]);
    const [category, setCategory] = useState([]);
    const [getAds, setGetAds] = useState(null);
    const {csrf} = useAuthContext();
    const [pagination, setPagination] = useState({
        current_page: 1,
        last_page: 1,
        total: 0,
    });

    const fetchData = async (page = 1) => {
        try {
            await csrf();
            const response = await axios.get('/api/blogs', {
                params: {page}
            });
            setBlogs(response?.data?.data?.data);
            setLatestBlogs(response?.data?.latest_blogs);
            setPagination({
                current_page: response?.data?.pagination?.current_page || 1,
                last_page: response?.data?.pagination?.last_page || 1,
                total: response?.data?.pagination?.total || 0,
            });
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching blogs.');
        }

        try {
            await csrf();
            const response = await axios.get('/api/blogs/category');
            setCategory(response?.data?.data);
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching blog categories.');
        }
    };

    const fetchAdsList = async (category = 'blog') => {
        try {
            await csrf();
            const response = await axios.get('/api/ads-list', {
                params: {category}
            });
            setGetAds(response?.data);
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching ads.');
        }
    }

    useEffect(() => {
        fetchData();
        fetchAdsList();
    }, []);

    const handlePageChange = (page) => {
        fetchData(page);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{'Blog'}</title>
                <meta name="description" content="Blog"/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container style={{paddingBottom: '40px'}}>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            <DynamicHtmlRender htmlString={getAds?.sidebar_left} />
                        </Col>

                        <Col md={8} sm={12}>
                            <Row>
                                <FloatMenu />

                                <Col sm={12} className={'text-center mt-3'} style={{padding: '10px'}}>
                                    <p className={'daily-task-title'}>Blog</p>
                                </Col>

                                <Col xs={12} className="blog-category">
                                    {category.map((item, index) => (
                                        <div className="category-init" key={index}>
                                            <div className="single">
                                                <Link to={`/blog/${item?.slug}`} />
                                                <img
                                                    src={item?.image ? `${process.env.REACT_APP_API_URL}/storage/${item.image}` : '/images/static/blur.jpg'}
                                                    alt={item?.image_alt}
                                                />
                                            </div>

                                            <span className="text-white">{item?.title}</span>
                                        </div>
                                    ))}
                                </Col>

                                <Col sm={12}>
                                    <Row className={'mt-3'}>
                                        {latestBlogs.length > 0 && (
                                            <Col sm={12}>
                                                <div className="trending__header">
                                                    <h2>Latest Blog</h2>
                                                </div>

                                                <Row className="trending__content">
                                                    {latestBlogs.map((item, index) => (
                                                        <Col key={index} xs={6} key={index}>
                                                            <div className="trending__content__single">
                                                                <Link to={`/blog-details/${item?.slug}`} />
                                                                <div className="trending__content__single__image">
                                                                    <img
                                                                        src={item?.image ? `${process.env.REACT_APP_API_URL}/storage/${item.image}` : '/images/static/blur.jpg'}
                                                                        alt={item?.image_alt}
                                                                    />
                                                                </div>
                                                                <div className="trending__content__single__text">
                                                                    <h6>{item?.title}</h6>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Col>
                                        )}

                                        {blogs.length > 0 ? (
                                            <>
                                                <Col sm={12} className={'pt-3'}>
                                                    <div className="news__wrap">
                                                        <div className="news__header">
                                                            <h2>All Blog</h2>
                                                        </div>

                                                        <div className="news__content">
                                                            {blogs.map((item, index) => (
                                                                <div key={index} className="news__content__single">
                                                                    <Link to={`/blog/${item?.slug}`}></Link>
                                                                    <div className="news__content__single__image">
                                                                        <img
                                                                            src={item?.image ? `${process.env.REACT_APP_API_URL}/storage/${item.image}` : '/images/static/avater.jpg'}
                                                                            alt={item?.image_alt}
                                                                        />
                                                                    </div>
                                                                    <div className="news__content__single__text">
                                                                        <h6>{item?.title}</h6>
                                                                        <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Pagination
                                                    currentPage={pagination.current_page}
                                                    totalPages={pagination.last_page}
                                                    onPageChange={handlePageChange}
                                                />
                                            </>
                                        ) : (
                                            <Col sm={12} className={'text-center'}>
                                                <p className={'text-white'}>No blog available.</p>
                                            </Col>
                                        )}

                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            <DynamicHtmlRender htmlString={getAds?.sidebar_right} />
                        </Col>
                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>

    );
};

const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;
  color: #ffffff;

  .daily-task-title {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    display: inline-block;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 80px;
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .trending {
    &__header {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      background: #FFFFFF;
      border: 1px solid gainsboro;
      border-radius: 4px 4px 0 0;

      h2 {
        font-size: 1.2858rem;
        font-weight: 600;
        line-height: 1.3333;
        color: #000;
      }

      a {
        //color: #007B00;
        cursor: pointer;
        text-decoration: none;
      }
    }

    &__content {
      padding-top: 10px;
      background: #FFFFFF;
      margin: 0;

      .col-6 {
        padding: 0 5px 10px 5px;
      }

      &__single {
        position: relative;
        overflow: hidden;

        &__text {
          position: absolute;
          bottom: 5px;
          left: 5px;
          right: 5px;
          box-shadow: 0 0 10px 20px rgba(0, 0, 0, 0.30);
          background: rgba(0, 0, 0, 0.30);

          h6 {
            font-size: 15px;
            font-weight: 600;;
            color: #FFFFFF;
          }
        }

        &__image {
          position: relative;
          padding-top: calc(150 / 260 * 100%);
          overflow: hidden;
          border-radius: 5px;

          img {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 2;
          cursor: pointer;
        }

        &:hover {
          img {
            transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
            transform: scale(1.10);
          }
        }
      }
    }
  }

  .news {
    &__wrap {
      background: #FFFFFF;
      border-width: 1px;
      border-radius: 4px;
      box-shadow: 1px 1px 2px -1px #ccc;
      color: #000;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      border: 1px solid gainsboro;
      border-radius: 4px 4px 0 0;

      h2 {
        font-size: 1.2858rem;
        font-weight: 600;;
        line-height: 1.3333;
      }
    }

    &__content {
      padding: 15px;

      &__single {
        position: relative;
        display: flex;
        gap: 10px;
        margin-bottom: 15px;
        height: 100px;
        overflow: hidden;
        padding: 5px;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 2;
          cursor: pointer;
        }

        &:hover {
          background: rgba(246, 150, 71, 0.5);

          img {
            transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
            transform: scale(1.15);
          }

        }

        &:last-child {
          margin-bottom: 0;
        }

        &__image {
          min-width: 135px;
          min-height: 90px;
          overflow: hidden;

          img {
            width: 135px;
            height: 90px;
            transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        &__text {
          h6 {
            font-size: 15px;
            font-weight: 700;
            color: #444;
            text-decoration: none;
            padding-bottom: 5px;
          }

          p {
            color: #777;
            font-size: 12px;
          }
        }
      }
    }
  }

  .blog-category {
    display: flex;
    
    .category-init {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
      
      .single {
        position: relative;
        height: 80px;
        width: 80px;
        overflow: hidden;
        border-radius: 50%;

        img {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
        }
      }

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        cursor: pointer;
      }

    }
    }
  }

`;

export default Blog;
