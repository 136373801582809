import {useState} from 'react';
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Container, Row, Col} from "react-bootstrap";
import {hover, text, title} from '../../styles/globalStyleVars';
import {Link} from "react-router-dom";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { ToastContainer } from 'react-toastify';
import useAuthContext from "../../context/AuthContext";


const Registration = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [referral_id, setReferralId] = useState('');
    const [terms_conditions, setTermsCondition] = useState(1);
    const {register} = useAuthContext();

    const handleTermsChange = (e) => {
        setTermsCondition(e.target.checked ? 1 : 0);
    };

    const handleRegister = async (event) => {
        event.preventDefault();
        register({
            name,
            email,
            password,
            password_confirmation,
            referral_id,
            terms_conditions,
        });
    }

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Registration</title>
                <meta name="description" content="Registration"/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                        <Col md={8} sm={12}>
                            <Row className={'mt-3'}>

                                <Col sm={12} className={'text-center'} style={{padding: '10px 10px 40px 10px'}}>
                                    <p className={'task-title'}>Create an <br/><b>Account!</b></p>
                                </Col>

                                <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                    <form onSubmit={handleRegister}>

                                        <div className="input-container">
                                            <AccountCircleIcon className={'icon'} />
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Full Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="input-container">
                                            <MailOutlineIcon className={'icon'} />
                                            <input
                                                className="form-control"
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="input-container">
                                            <LockIcon className={'icon'} />
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="input-container">
                                            <LockIcon className={'icon'} />
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Confirm Password"
                                                value={password_confirmation}
                                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <h3 className={'referral-title'}>Referral ID</h3>

                                        <div className="input-container">
                                            <GroupAddIcon className={'icon'} />
                                            <input
                                                className="form-control"
                                                type="number"
                                                placeholder="User Id"
                                                value={referral_id}
                                                onChange={(e) => setReferralId(e.target.value)}
                                            />
                                        </div>

                                        <div className="input-container">
                                            <input
                                                className={'me-3'}
                                                type={'checkbox'}
                                                id={'terms_conditions'}
                                                onChange={handleTermsChange}
                                                checked={terms_conditions === 1}
                                                required
                                            />
                                            <label htmlFor="terms_conditions">Terms & Conditions</label>
                                        </div>

                                        <div className="mb-3 mt-4">
                                            <button className={'submit-btn'} type="submit">CREATE</button>
                                        </div>

                                        <div className="mb-3 mt-4 sign-up">
                                            <p>Already have an account?<span className={'me-2'}></span></p>
                                            <Link to={'/'}> Sign In</Link>
                                        </div>

                                    </form>
                                </Col>

                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>
    );
};


const StyledSection = styled.section`
  min-height: 100vh;

  .task-title {
    font-size: 50px;
    display: inline-block;
    text-align: center;
    line-height: normal;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  form {
    padding: 30px;
    border-radius: 15px;
    background-color: #caced2;
  }
  
  label {
    font-weight: 600;
  }

  .input-container {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
  }

  .icon {
    padding: 10px;
    background-color: transparent;
    min-width: 50px;
    text-align: center;
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 1;
    height: calc(100% - 4px);
  }

  .form-control {
    width: 100%;
    padding: 10px 10px 10px 60px;
    outline: none;
    font-weight: 600;
    border: 2px solid ${hover};
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 15px;

    &:hover {
      border: 2px solid ${hover};
    }

    &:focus {
      border: 2px solid ${hover};
    }
  }

  .referral-title {
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 15px 0;
  }

  .sign-up {
    display: flex;

    p {
      font-weight: 600;
      font-style: italic;
    }

    a {
      color: #ad0a87;
      font-weight: 600;
    }
  }

  .terms_condition_group {
    display: flex;

    input {
      height: 24px;
      width: 24px;
    }
  }

  .submit-btn {
    padding: 10px 20px;
    border: 2px solid ${hover};
    border-radius: 10px;
    background: #ffffff;
    font-weight: 600;

    &.reset-btn {
      padding: 0 20px;
    }
  }

`;

export default Registration;
