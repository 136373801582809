import React, { useEffect } from 'react';

function DynamicHtmlRenderer({ htmlString }) {
    useEffect(() => {
        const div = document.createElement('div');
        div.innerHTML = htmlString;

        // Extract and execute scripts
        const scripts = div.querySelectorAll('script');
        scripts.forEach((script) => {
            const newScript = document.createElement('script');
            newScript.type = 'text/javascript';
            if (script.src) {
                newScript.src = script.src;
                document.body.appendChild(newScript);
            } else {
                newScript.innerHTML = script.innerHTML;
                document.body.appendChild(newScript);
            }
            script.remove();
        });
    }, [htmlString]);

    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export default DynamicHtmlRenderer;