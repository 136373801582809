import React, {useState} from 'react';
import axios from "../../api/axios";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Container, Row, Col} from "react-bootstrap";
import {hover, text, title} from '../../styles/globalStyleVars';
import {Link, useHistory} from "react-router-dom";
import FloatMenu from "../../components/FloatMenu";
import useAuthContext from "../../context/AuthContext";
import { ToastContainer, toast } from 'react-toastify';

const ProfileSetting = () => {
    const {user, csrf} = useAuthContext();
    const [name, setName] = useState(user?.name);
    const [image, setImage] = useState(user?.image);
    const [phone, setPhone] = useState(user?.phone);
    const [bkash, setBkash] = useState(user?.bkash === "null" ? "" : user?.bkash ?? "");
    const [nogod, setNogod] = useState(user?.nogod === "null" ? "" : user?.nogod ?? "");
    const [paytm, setPaytm] = useState(user?.paytm === "null" ? "" : user?.paytm ?? "");
    const [payoneer, setPayoneer] = useState(user?.payoneer === "null" ? "" : user?.payoneer ?? "");
    const [paypal, setPaypal] = useState(user?.paypal === "null" ? "" : user?.paypal ?? "");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();

    const handleProfile = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        try {
            await csrf();
            const formData = new FormData();
            formData.append('name', name);
            formData.append('phone', phone);
            formData.append('bkash', bkash);
            formData.append('nogod', nogod);
            formData.append('paytm', paytm);
            formData.append('payoneer', payoneer);
            formData.append('paypal', paypal);

            if (image) {
                formData.append('image', image);
            }

            const response = await axios.post('/api/profile-setting', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (err) {
            if (err.response && err.response.status === 422) {
                const errors = err.response.data.errors;
                Object.keys(errors).forEach((field) => {
                    errors[field].forEach((message) => {
                        toast.error(message);
                    });
                });
            } else {
                console.error('Error fetching user data:', err);
                toast.error('An unexpected error occurred.');
            }
        } finally {
            setIsSubmitting(false);
            toast(
                <>
                    Profile updated successfully.
                    <br />
                    Refresh your page.
                </>
            );
            history.push('/profile-setting');
        }
    }

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Profile Setting</title>
                <meta name="description" content="Profile Setting"/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container style={{paddingBottom: '40px'}}>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                        <Col md={8} sm={12}>
                            <Row>
                                <FloatMenu />

                                <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                    <p className={'daily-task-title'}>Profile Setting</p>
                                </Col>

                                <form onSubmit={handleProfile}>
                                    <Col sm={12} className={'profile justify-content-between'}>
                                        <div className={'d-flex'}>
                                            <div>
                                                <img
                                                    src={user?.image ? `${process.env.REACT_APP_API_URL}/storage/${user.image}` : '/images/static/avater.jpg'}
                                                    alt="profile-picture"
                                                />
                                            </div>

                                            <div>
                                                <h4>{name}</h4>
                                                <p>Balance: {user?.balance}</p>
                                                <p>{user?.email}</p>
                                                <p>User ID: {user?.user_id}</p>
                                            </div>
                                        </div>
                                        <div className={'d-flex flex-column align-items-end'}>
                                            <label>Select Profile Photo</label>
                                            <input
                                                className={'form-control'}
                                                type={'file'}
                                                onChange={(e) => setImage(e.target.files[0])}
                                                name={'profile-photo'}
                                                style={{width: '110px'}}
                                            />
                                        </div>
                                    </Col>

                                    <Col sm={12} className={'text-center'} style={{padding: '10px 0'}}>
                                        <div className="text-left mb-3">
                                            <label>Full name</label>
                                            <input
                                                className={'form-control'}
                                                type={'text'}
                                                name={'name'}
                                                value={name || ""}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder={'Full name...'}
                                            />
                                        </div>

                                        <div className="text-left mb-3">
                                            <label>Phone Number</label>
                                            <input
                                                className={'form-control'}
                                                type={'number'}
                                                name={'phone'}
                                                value={phone || ""}
                                                onChange={(e) => setPhone(e.target.value)}
                                                placeholder={'01XXXXXXXXX'}
                                            />
                                        </div>

                                        <div className="text-left mb-3 mt-5 text-center">
                                            <label style={{fontSize: '30px'}}>Advance Payment</label>
                                        </div>

                                        <div className="text-left mb-3">
                                            <label>Bkash</label>
                                            <input
                                                className={'form-control'}
                                                type={'number'}
                                                name={'bkash'}
                                                value={bkash === "null" ? "" : bkash}
                                                onChange={(e) => setBkash(e.target.value)}
                                                placeholder={'01XXXXXXXXX'}
                                            />
                                        </div>

                                        <div className="text-left mb-3">
                                            <label>Nogod</label>
                                            <input
                                                className={'form-control'}
                                                type={'number'}
                                                name={'nogod'}
                                                value={nogod === "null" ? "" : nogod}
                                                onChange={(e) => setNogod(e.target.value)}
                                                placeholder={'01XXXXXXXXX'}
                                            />
                                        </div>

                                        <div className="text-left mb-3">
                                            <label>Paytm</label>
                                            <input
                                                className={'form-control'}
                                                type={'number'}
                                                name={'paytm'}
                                                value={paytm === "null" ? "" : paytm}
                                                onChange={(e) => setPaytm(e.target.value)}
                                                placeholder={'91XXXXXXXXX'}
                                            />
                                        </div>

                                        <div className="text-left mb-3">
                                            <label>Payoneer</label>
                                            <input
                                                className={'form-control'}
                                                type={'text'}
                                                name={'payoneer'}
                                                value={payoneer === "null" ? "" : payoneer}
                                                onChange={(e) => setPayoneer(e.target.value)}
                                                placeholder={'example@email.com'}
                                            />
                                        </div>

                                        <div className="text-left mb-3">
                                            <label>Paypal</label>
                                            <input
                                                className={'form-control'}
                                                type={'text'}
                                                name={'paypal'}
                                                value={paypal === "null" ? "" : paypal}
                                                onChange={(e) => setPaypal(e.target.value)}
                                                placeholder={'example@email.com'}
                                            />
                                        </div>

                                        <div className="mt-4">
                                            <button
                                                className={'submit-btn'}
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? 'Submitting...' : 'SUBMIT'}
                                            </button>
                                        </div>

                                        <div className="mb-3 mt-4">
                                            <Link to={'change-password'} className={'submit-btn reset-btn'}> CHANGE PASSWORD</Link>
                                        </div>
                                    </Col>
                                </form>
                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>
    );
};


const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;
  
  .text-left {
    text-align: left;
  }
  
  .profile {
    background-color: ${hover};
    display: flex;
    align-items: center;
    padding: 10px;

    @media (max-width: 767px) {
      align-items: flex-start;
    }

    h4, p {
      font-weight: 600;
    }

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
      overflow: hidden;
      margin: 0 20px 0 0;

      @media (max-width: 767px) {
        height: 60px;
        width: 60px;
        margin: 0 10px 0 0;
      }
    }
  }
  
  .daily-task-title {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    display: inline-block;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 80px;
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
    }
  }
  
  label {
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 5px;
  }
  
  .form-control {
    font-weight: 600;
    border: 2px solid ${hover};

    &:hover {
      border: 2px solid ${hover};
    }

    &:focus {
      border: 2px solid ${hover};
    }
  }
  
  .submit-btn {
    padding: 10px 20px;
    border: 2px solid ${hover};
    border-radius: 10px;
    background: #ffffff;
    font-weight: 600;
    
    &.reset-btn {
      padding: 0 20px;
    }
  }
`;

export default ProfileSetting;
