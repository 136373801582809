import React, {useEffect, useState} from 'react';
import axios from "../../api/axios";
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async';
import {Container, Row, Col} from "react-bootstrap";
import {hover, text} from "../../styles/globalStyleVars";
import {useParams} from "react-router-dom";
import FloatMenu from "../../components/FloatMenu";
import DynamicHtmlRender from "../../components/DynamicHtmlRender";
import useAuthContext from "../../context/AuthContext";
import {toast, ToastContainer} from "react-toastify";

const UserGuideDetails = () => {
    const { slug } = useParams();
    const [userGuide, setUserGuide] = useState([]);
    const [getAds, setGetAds] = useState(null);
    const {csrf} = useAuthContext();

    const fetchData = async () => {
        try {
            await csrf();
            const response = await axios.get(`/api/user-guide/${slug}`);
            setUserGuide(response?.data?.data || {});
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching user guide details.');
        }
    };

    const fetchAdsList = async (category = 'user_guide_details') => {
        try {
            await csrf();
            const response = await axios.get('/api/ads-list', {
                params: {category}
            });
            setGetAds(response?.data);
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching ads.');
        }
    }

    useEffect(() => {
        fetchData();
        fetchAdsList();
    }, [slug]);

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>User Guide Details</title>
                <meta name="description" content="User Guide Details" />
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container style={{paddingBottom: '40px'}}>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            <DynamicHtmlRender htmlString={getAds?.home_sidebar_left} />
                        </Col>

                        <Col md={8} sm={12}>
                            <Row>
                                <FloatMenu />

                                <Col sm={12}>
                                    <Row className={'mt-3'}>
                                        <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                            <p className={'daily-task-title'}>User Guide Details</p>
                                        </Col>

                                        <Col sm={12}>

                                            <h2>{userGuide?.title}</h2>
                                            <div dangerouslySetInnerHTML={{ __html: userGuide?.description }} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            <DynamicHtmlRender htmlString={getAds?.home_sidebar_right} />
                        </Col>
                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>

    );
};

const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;
  color: #ffffff;

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, b, ol, ul, li, p, span {
    color: #ffffff !important;
  }
  
  .daily-task-title {
    font-size: 24px;
    font-weight: 600;
    color: #000000 !important;
    display: inline-block;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 80px;
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

export default UserGuideDetails;
