import { Redirect } from 'react-router-dom';
import useAuthContext from '../context/AuthContext';
import {Loading} from "../components/Loading";

export default function GuestLayout({ children }) {
    const { user, loading } = useAuthContext();

    if (loading) {
        return <Loading />;
    }

    if (user) {
        return <Redirect to="/home" />;
    }

    return <>{children}</>;
}
