import React, {useEffect, useState} from 'react';
import axios from "../../api/axios";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Container, Row, Col} from "react-bootstrap";
import {hover, text, title} from '../../styles/globalStyleVars';
import {Link} from "react-router-dom";
import FloatMenu from "../../components/FloatMenu";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { ToastContainer, toast } from 'react-toastify';
import useAuthContext from "../../context/AuthContext";

const Task = () => {
    const [isTaskLoaded, setTaskIsLoaded] = useState(true);
    const [tasks, setTasks] = useState([]);
    const {user, csrf} = useAuthContext();

    const fetchData = async () => {
        try {
            setTaskIsLoaded(true);
            await csrf();
            const response = await axios.get('/api/tasks-check');
            setTasks(response?.data?.tasks);
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching tasks.');
        } finally {
            setTaskIsLoaded(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Task Check</title>
                <meta name="description" content="Task Check"/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                        <Col md={8} sm={12}>
                            <Row>
                                <FloatMenu />

                                <Col sm={12}>
                                    <Row>
                                        <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                            <p className={'daily-task-title'}>Daily Lesson Check</p>
                                        </Col>

                                        {isTaskLoaded ? (
                                            <>
                                                <Col xs={4}><div className="daily-task-card is-task-loaded"></div></Col>
                                                <Col xs={4}><div className="daily-task-card is-task-loaded"></div></Col>
                                                <Col xs={4}><div className="daily-task-card is-task-loaded"></div></Col>
                                            </>
                                        ) : (
                                            tasks?.length > 0 ? (
                                                <>
                                                    {tasks.map((task) => (
                                                        <Col xs={4} key={task?.id}>
                                                            <Link to={`/tasks-check/${task?.slug}`} className="daily-task-card">
                                                                <div className="icon-wrap">
                                                                    <LockOpenIcon className="lock-icon" />
                                                                </div>
                                                                <p className="task-title">{task?.title}</p>
                                                                <p className="task-bonus">
                                                                    {user?.currency_sign}{task?.price ? parseFloat(task.price).toFixed(2) : "0.00"}
                                                                </p>
                                                            </Link>
                                                        </Col>
                                                    ))}
                                                </>
                                            ) : (
                                                <Col sm={12}>
                                                    <div className="daily-task-card">
                                                        <p>No items available.</p>
                                                    </div>
                                                </Col>
                                            )
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>
                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>
    );
};


const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;

  .is-task-loaded {
    opacity: 20%;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-name: change-color;
  }

  @keyframes change-color {
    from {
      background-color: #ffffff;
    }
    to {
      background-color: #ddb787;
    }
  }

  .result-wrap {
    background: ${hover};
    padding-top: 10px;
    padding-bottom: 10px;

    .item-card {
      p {
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .daily-task-title {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    display: inline-block;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 80px;
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .daily-task-card {
    background: white;
    padding: 10px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    min-height: 75px;
    position: relative;

    &.withdraw {
      background: #9797a5;
    }

    .notify {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 50%;
    }

    @media (max-width: 991px) {
      flex-direction: column;
      justify-content: center;
      height: 100px;
      margin: 10px 0 30px 0;

      .icon-wrap {
        position: absolute;
        top: 0;
        left: 0;
      }

      .task-bonus {
        background: #F69647;
        color: #fff;
        position: absolute;
        bottom: -20px;
        font-weight: 600;
        padding: 5px 15px;
        border-radius: 15px;
        z-index: 1;;
      }
    }
  }

  .task-title {
    font-weight: 600;
  }

  .lock-icon {
    height: 30px;
    width: 30px;
    color: ${hover};
  }
`;

export default Task;
