import {useEffect} from "react";
import {
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import GlobalStyle from "./styles/globalStyle";
import 'react-toastify/dist/ReactToastify.css';

// page imports
import Home from './pages/home';
import Error from './pages/404';
import GetBonus from "./pages/getBonus";
import DailyTask from "./pages/dailyTask";
import DailyTaskDetail from "./pages/dailyTask/single";
import Withdraw from "./pages/withdraw";
import WithdrawHistory from "./pages/withdraw/history";
import UserGuide from "./pages/userGuide";
import UserGuideDetail from "./pages/userGuide/single";
import Blog from "./pages/blog";
import BlogCategory from "./pages/blog/category";
import BlogDetail from "./pages/blog/single";
import ProfileSetting from "./pages/profileSettings";
import Support from "./pages/support";
import Login from "./pages/layout/login";
import Registration from "./pages/layout/registration";
import ForgetPassword from "./pages/layout/forget-password";
import ChangePassword from "./pages/layout/change-password";
import ResetPassword from "./pages/layout/reset-password";
import GuestLayout from "./layout/GuestLayout";
import AuthLayout from "./layout/AuthLayout";
import Page from "./pages/page";
import TaskCheck from "./pages/dailyTaskCheck";
import TaskItemCheck from "./pages/dailyTaskCheck/item";
import TaskItemDetailCheck from "./pages/dailyTaskCheck/single";


function App() {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/home') {
      document.body.classList.add('in-home-page')
    } else {
      document.body.classList.remove('in-home-page')
    }

    window.scroll(0, 0)
  }, [location.pathname])

  return (
  <>
    <GlobalStyle/>

    <div className="App" id="smooth-wrapper">
      <div id="smooth-content" style={{position: 'relative'}}>
        <Switch location={location} key={'location.pathname'}>

          {/* Admin routes */}
          <Route path="/tasks-check" exact>
            <AuthLayout>
              <TaskCheck />
            </AuthLayout>
          </Route>
          <Route path="/tasks-check/:slug" exact>
            <AuthLayout>
              <TaskItemCheck />
            </AuthLayout>
          </Route>
          <Route path="/items-details-check/:task/:slug" exact>
            <AuthLayout>
              <TaskItemDetailCheck />
            </AuthLayout>
          </Route>

          {/* Auth routes */}
          <Route path="/home" exact>
            <AuthLayout>
              <Home />
            </AuthLayout>
          </Route>
          <Route path="/get-bonus" exact>
            <AuthLayout>
              <GetBonus />
            </AuthLayout>
          </Route>
          <Route path="/daily-task/:slug" exact>
            <AuthLayout>
              <DailyTask />
            </AuthLayout>
          </Route>
          <Route path="/daily-task/:task/:slug" exact>
            <AuthLayout>
              <DailyTaskDetail />
            </AuthLayout>
          </Route>
          <Route path="/withdraw" exact>
            <AuthLayout>
              <Withdraw />
            </AuthLayout>
          </Route>
          <Route path="/withdraw-history" exact>
            <AuthLayout>
              <WithdrawHistory />
            </AuthLayout>
          </Route>
          <Route path="/user-guide" exact>
            <AuthLayout>
              <UserGuide />
            </AuthLayout>
          </Route>
          <Route path="/user-guide/:slug" exact>
            <AuthLayout>
              <UserGuideDetail />
            </AuthLayout>
          </Route>
          <Route path="/blog" exact>
            <AuthLayout>
              <Blog />
            </AuthLayout>
          </Route>
          <Route path="/blog/:slug" exact>
            <AuthLayout>
              <BlogCategory />
            </AuthLayout>
          </Route>
          <Route path="/blog-details/:slug" exact>
            <AuthLayout>
              <BlogDetail />
            </AuthLayout>
          </Route>
          <Route path="/profile-setting" exact>
            <AuthLayout>
              <ProfileSetting />
            </AuthLayout>
          </Route>
          <Route path="/support" exact>
            <AuthLayout>
              <Support />
            </AuthLayout>
          </Route>
          <Route path="/change-password" exact>
            <AuthLayout>
              <ChangePassword />
            </AuthLayout>
          </Route>

          {/* Guest routes */}
          <Route path="/" exact>
            <GuestLayout>
              <Login />
            </GuestLayout>
          </Route>
          <Route path="/registration" exact>
            <GuestLayout>
              <Registration />
            </GuestLayout>
          </Route>
          <Route path="/forget-password" exact>
            <GuestLayout>
              <ForgetPassword />
            </GuestLayout>
          </Route>
          <Route path="/password-reset/:token" exact>
            <GuestLayout>
              <ResetPassword />
            </GuestLayout>
          </Route>

          {/* Catch all */}
          <Route path="/about-us" exact>
            <Page />
          </Route>
          <Route path="/privacy-policy" exact>
            <Page />
          </Route>
          <Route path="/terms-conditions" exact>
            <Page />
          </Route>
          <Route path="/contact-us" exact>
            <Page />
          </Route>
          <Route>
            <Error />
          </Route>
        </Switch>
      </div>
    </div>
  </>
  );
}

export default App;
