import React, {useEffect, useState} from 'react';
import axios from "../../api/axios";
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async';
import {Container, Row, Col} from "react-bootstrap";
import {hover, text} from "../../styles/globalStyleVars";
import {useLocation} from "react-router-dom";
import FloatMenu from "../../components/FloatMenu";
import useAuthContext from "../../context/AuthContext";
import {toast, ToastContainer} from "react-toastify";

const Page = () => {
    const location = useLocation();
    const slug = location.pathname.startsWith('/')
        ? location.pathname.slice(1)
        : location.pathname;
    const [pageDetails, setPageDetails] = useState([]);
    const {csrf} = useAuthContext();

    const fetchData = async () => {
        try {
            await csrf();
            const response = await axios.get(`/api/page/${slug}`);
            setPageDetails(response?.data?.data || {});
        } catch (err) {
            console.error('Error fetching: ', err);
            toast.error(err?.response?.data?.message || 'Error fetching user page details.');
        }
    };

    useEffect(() => {
        fetchData();
    }, [slug]);

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{pageDetails?.meta_title ?? pageDetails?.title}</title>
                <meta name="description" content={pageDetails?.meta_description ?? ""} />
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container style={{paddingBottom: '40px'}}>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            <p>Ads</p>
                        </Col>

                        <Col md={8} sm={12}>
                            <Row>
                                <FloatMenu />

                                <Col sm={12}>
                                    <Row className={'mt-3'}>
                                        <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                            <p className={'daily-task-title'}>{pageDetails?.title}</p>
                                        </Col>

                                        <Col sm={12}>

                                            <h2>{pageDetails?.subtitle}</h2>
                                            <p>{pageDetails?.short_desc}</p>
                                            <div dangerouslySetInnerHTML={{ __html: pageDetails?.description }} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            <p>Ads</p>
                        </Col>
                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>

    );
};

const StyledSection = styled.section`
  background-color: ${text};
  min-height: 100vh;
  color: #ffffff;
  
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, b, ol, ul, li, p, span {
    color: #ffffff !important;
    margin-bottom: 20px;
  }
  
  .daily-task-title {
    font-size: 24px;
    font-weight: 600;
    color: #000000 !important;
    display: inline-block;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 80px;
    position: relative;
    text-decoration: none;
    border: 3px solid ${hover};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

export default Page;
