import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Container, Row, Col} from "react-bootstrap";
import {hover, title} from '../../styles/globalStyleVars';
import {Link} from "react-router-dom";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import useAuthContext from "../../context/AuthContext";
import {toast, ToastContainer} from "react-toastify";
import Footer from "../../components/Footer";
import axios from "../../api/axios";


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {login, errors, csrf} = useAuthContext();
    const [footerData, setFooterData] = useState(null);

    const handleLogin = async (e) => {
        e.preventDefault();
        login({email, password});
    };

    const fetchData = async () => {
        try {
            await csrf();
            const response = await axios.get('/api/footer');
            setFooterData(response?.data);
        } catch (err) {
            console.error('Error fetching: ', err);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Login</title>
                <meta name="description" content="Login"/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                        <Col md={8} sm={12}>
                            <Row className={'mt-3'}>

                                <Col sm={12} className={'text-center'}>
                                    <img className={'logo'} src="/images/logo.png" alt=""/>
                                </Col>

                                <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                    <p className={'task-title'}>Turn 60 Minutes</p>
                                    <p className={'task-title'}>into <span>CASH</span></p>
                                </Col>

                                <Col sm={12} className={'text-center'} style={{padding: '10px'}}>
                                    <form onSubmit={handleLogin}>

                                        <div className="input-container">
                                            <MailOutlineIcon className={'icon'} />
                                            <input
                                                className="form-control"
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="input-container">
                                            <LockIcon className={'icon'} />
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>

                                        {errors && <p className={'text-danger'}>{errors}</p>}

                                        <div className="mb-3 mt-4">
                                            <button className={'submit-btn'} type="submit">LOG IN</button>
                                        </div>

                                        <div className="mb-3 mt-4 forget-password">
                                            <Link to={'forget-password'}>Forget Password?</Link>
                                        </div>

                                        <div className="mb-3 mt-4 sign-up">
                                            <p>Don't have an account?<span className={'me-2'}></span></p>
                                            <Link to={'registration'}> Sign Up</Link>
                                        </div>

                                    </form>
                                </Col>

                                <Col sm={12} className={'login-details'} style={{padding: '10px'}}>
                                    <p className={'title'}>How it Works</p>

                                    <div>
                                        <img className={''} src="/images/login-bg.png" alt=""/>
                                    </div>

                                    <p className={'sub-title'}>
                                        First you need to create account by providing information. Once the account is created you can start your journey with SixtyFrog.
                                    </p>

                                    <div>
                                        <img className={''} src="/images/withdraw-bg.png" alt=""/>
                                    </div>

                                    <p className={'sub-title'}>
                                        Learn about Cryptocurrency. Complete your daily tasks, receive daily bonuses and earn by inviting friends.
                                    </p>

                                    <div>
                                        <img className={''} src="/images/task-bg.jpg" alt=""/>
                                    </div>

                                    <p className={'sub-title'}>
                                        Once all the tasks are completed, choose the payment channel according to your convenience and withdraw the money.
                                    </p>
                                </Col>

                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                    </Row>
                    <Footer data={footerData} />
                </Container>
            </StyledSection>
        </HelmetProvider>
    );
};


const StyledSection = styled.section`
  min-height: 100vh;

  .logo {
    width: 300px;

    @media (max-width: 400px) {
      width: 200px;
    }
  }

  .task-title {
    font-size: 35px;
    line-height: 1.2;
    font-weight: 600;

    span {
      font-size: 45px;
    }
  }

  form {
    padding: 30px;
    border-radius: 15px;
    background-color: #caced2;
  }

  .input-container {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
  }

  .icon {
    padding: 10px;
    background-color: transparent;
    min-width: 50px;
    text-align: center;
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 1;
    height: calc(100% - 4px);
  }

  .form-control {
    width: 100%;
    padding: 10px 10px 10px 60px;
    outline: none;
    font-weight: 600;
    border: 2px solid ${hover};
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 15px;

    &:hover {
      border: 2px solid ${hover};
    }

    &:focus {
      border: 2px solid ${hover};
    }
  }

  .forget-password {
    display: flex;

    a {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .sign-up {
    display: flex;

    p {
      font-weight: 600;
      font-style: italic;
    }

    a {
      color: #ad0a87;
      font-weight: 600;
    }
  }

  .terms_condition_group {
    display: flex;

    input {
      height: 24px;
      width: 24px;
    }
  }

  .submit-btn {
    padding: 10px 20px;
    border: 2px solid ${hover};
    border-radius: 10px;
    background: #ffffff;
    font-weight: 600;

    &.reset-btn {
      padding: 0 20px;
    }
  }

  .login-details {
    text-align: center;

    .title {
      font-size: 35px;
      font-weight: 600;
      padding: 0 0 20px 0;
    }

    img {
      border-radius: 50%;
      width: 300px;
      height: 300px;
      background-color: rgba(218, 228, 245, 0.3);
    }
    
    .sub-title {
      margin: 25px 0;
    }

  }
`;

export default Login;
