import {useEffect, useState} from 'react';
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Container, Row, Col} from "react-bootstrap";
import {hover, text, title} from '../../styles/globalStyleVars';
import LockIcon from "@mui/icons-material/Lock";
import { toast, ToastContainer } from 'react-toastify';
import useAuthContext from '../../context/AuthContext';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [status, setStatus] = useState(null);
    const {csrf} = useAuthContext();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const {token} = useParams();
    const history = useHistory();

    useEffect(() => {
        setEmail(searchParams.get('email'));
    })

    const handleSubmit = async (event) => {
        event.preventDefault();
        await csrf();
        try {
            const response = await axios.post('/reset-password', {
                email,
                token,
                password,
                password_confirmation,
            });
            setStatus(response.data.status);
            toast.success('Password reset successful!');
            history.push('/');
        } catch (e) {
            handleErrors(e);
        }
    };

    const handleErrors = (error) => {
        if (error?.response?.status === 422) {
            const validationErrors = error?.response?.data?.errors || {};
            Object.keys(validationErrors).forEach((field) => {
                validationErrors[field].forEach((message) => {
                    toast.error(message);
                });
            });
        } else {
            console.error('Error:', error);
            toast.error('An unexpected error occurred.');
        }
    };

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Reset Password</title>
                <meta name="description" content="Reset Password"/>
            </Helmet>

            <ToastContainer />

            <StyledSection>
                <Container>
                    <Row>
                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                        <Col md={8} sm={12}>
                            <Row className={'mt-3'}>
                                <Col sm={12} className={'text-center'} style={{padding: '10px 10px 60px 10px'}}>
                                    <p className={'task-title'}>Reset <br/> Password</p>
                                </Col>

                                <Col sm={12} className={'text-center'} style={{padding: '10px'}}>

                                    {status && <div className="bg-success text-white my-2 py-2">{status}</div>}

                                    <form onSubmit={handleSubmit}>
                                        <div className="input-container">
                                            <LockIcon className={'icon'} />
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="input-container">
                                            <LockIcon className={'icon'} />
                                            <input
                                                className="form-control"
                                                type="password"
                                                placeholder="Confirm Password"
                                                value={password_confirmation}
                                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="mb-3 mt-4">
                                            <button className={'submit-btn'} type="submit">SAVE</button>
                                        </div>

                                    </form>
                                </Col>

                            </Row>
                        </Col>

                        <Col md={2} sm={0} className={'ads'}>
                            {/*<p>Ads</p>*/}
                        </Col>

                    </Row>
                </Container>
            </StyledSection>
        </HelmetProvider>
    );
};


const StyledSection = styled.section`
  min-height: 100vh;

  .task-title {
    font-size: 50px;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    line-height: normal;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .input-container {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
  }

  .icon {
    padding: 10px;
    background-color: transparent;
    min-width: 50px;
    text-align: center;
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 1;
    height: calc(100% - 4px);
  }

  .form-control {
    width: 100%;
    padding: 10px 10px 10px 60px;
    outline: none;
    font-weight: 600;
    border: 2px solid ${hover};
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 15px;

    &:hover {
      border: 2px solid ${hover};
    }

    &:focus {
      border: 2px solid ${hover};
    }
  }

  .forget-password {
    display: flex;

    a {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .sign-up {
    display: flex;

    p {
      font-weight: 600;
      font-style: italic;
    }

    a {
      color: #ad0a87;
      font-weight: 600;
    }
  }

  .terms_condition_group {
    display: flex;

    input {
      height: 24px;
      width: 24px;
    }
  }

  .submit-btn {
    padding: 10px 20px;
    border: 2px solid ${hover};
    border-radius: 10px;
    background: #ffffff;
    font-weight: 600;

    &.reset-btn {
      padding: 0 20px;
    }
  }

`;

export default ResetPassword;
