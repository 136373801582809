import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {hover, text} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import useAuthContext from '../context/AuthContext';

const Menu = () => {
    const {user} = useAuthContext();
    return (
        <StyledComponent>
            <Container className={'menu-container'}>
                <Row>
                    <Col sm={12} className={'profile'}>
                        <div>
                            <img
                                src={user?.image ? `${process.env.REACT_APP_API_URL}/storage/${user.image}` : '/images/static/avater.jpg'}
                                alt="profile-picture"
                            />
                        </div>

                        <div>
                            <h4>{user?.name}</h4>
                            <p>{user?.email}</p>
                            <p>ID: {user?.user_id}</p>
                        </div>
                    </Col>

                    <Col md={6} sm={12} className={'item-card'}>
                        <p>Total Earning: {user?.currency_sign}{user?.total_earning ? parseFloat(user?.total_earning).toFixed(2) : "0.00"}</p>
                    </Col>

                    <Col md={6} sm={12} className={'item-card'}>
                        <p>Today's Earning: {user?.currency_sign}{user?.today_earning ? parseFloat(user?.today_earning).toFixed(2) : "0.00"}</p>
                    </Col>

                    <Col md={6} sm={12} className={'item-card'}>
                        <p>Total Referral: {user?.referral_users}</p>
                    </Col>

                    <Col md={6} sm={12} className={'item-card'}>
                        <p>Referral Bonus: {user?.currency_sign}{user?.bonus_sent ? parseFloat(user?.bonus_sent).toFixed(2) : "0.00"}</p>
                    </Col>

                    <Col md={6} sm={12} className={'item-card'}>
                        <p>Referral Pending: {user?.currency_sign}{user?.bonus_pending ? parseFloat(user?.bonus_pending).toFixed(2) : "0.00"}</p>
                    </Col>

                    <Col md={6} sm={12} className={'item-card'}></Col>

                    <Col sm={12} className={'menu'}>
                        <div className="icon">
                            <Link to="/user-guide">
                                <MenuBookIcon className={'menu-logo'} />
                            </Link>
                        </div>
                        <div className="icon">
                            <Link to="/blog">
                                <NewspaperIcon className={'menu-logo'} />
                            </Link>
                        </div>
                        <div className="icon home-icon">
                            <p>Balance <br/> {user?.currency_sign}{user?.balance}</p>
                        </div>
                        <div className="icon">
                            <Link to="/profile-setting">
                                <ManageAccountsIcon className={'menu-logo'} />
                            </Link>
                        </div>
                        <div className="icon">
                            <Link to="/support">
                                <SupportAgentIcon className={'menu-logo'} />
                            </Link>
                        </div>
                    </Col>

                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  @media (max-width: 767px) {
    padding: 0;
  }
  
  .menu-container {
    margin: 0;
    min-width: 100%;
  }
  
  .profile {
    background-color: ${hover};
    display: flex;
    align-items: center;
    padding: 10px;

    @media (max-width: 767px) {
      align-items: flex-start;
    }

    h4, p {
      font-weight: 600;
    }

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
      overflow: hidden;
      margin: 0 20px 0 0;

      @media (max-width: 767px) {
        height: 60px;
        width: 60px;
        margin: 0 10px 0 0;
      }
    }
  }
  
  .item-card {
    background: ${hover};
    
    p {
      font-weight: 600;
      color: white;
      margin: 0;
      padding: 3px 10px;
      //background: ${text};
    }
  }
  
  .menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    background-color: ${hover};
    padding-top: 15px;
    
    .icon {
      a {
        padding: 20px;
        
        @media (max-width: 400px) {
          padding: 10px;
        }
        
        .menu-logo {
          color: #ffffff;
          transition: color .3s ease;

          &:hover {
            color: ${text}
          }
        }
      }
    }
    
    .home-icon {
      text-align: center;
      padding: 5px 15px;
      border-radius: 5px 5px 0 0;
      background: ${text};
      
      p {
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
`;

export default Menu;
