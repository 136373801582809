import React from 'react';
import {hover} from '../styles/globalStyleVars';
import styled from "styled-components";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {

        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            if (
                i === 1 ||
                i === totalPages ||
                (i >= currentPage - 1 && i <= currentPage + 1)
            ) {
                pages.push(i);
            } else if (
                (i === currentPage - 2 || i === currentPage + 2) &&
                pages[pages.length - 1] !== '...'
            ) {
                pages.push('...');
            }
        }
        return pages;
    };

    return (
        <StyledSection>
            <div className="pagination">
                {currentPage > 1 && (
                    <button className={`arrow-btn`} onClick={() => onPageChange(currentPage - 1)}>&lt;</button>
                )}
                {getPageNumbers().map((page, index) =>
                    page === '...' ? (
                        <span key={index} className="dots">...</span>
                    ) : (
                        <button
                            key={index}
                            className={`page-number ${page === currentPage ? 'active' : ''}`}
                            onClick={() => onPageChange(page)}
                        >
                            {page}
                        </button>
                    )
                )}
                {currentPage < totalPages && (
                    <button className={`arrow-btn`} onClick={() => onPageChange(currentPage + 1)}>&gt;</button>
                )}
            </div>
        </StyledSection>
    );
};

const StyledSection = styled.section`
  padding-top: 20px;
  
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .page-number, .dots, .arrow-btn {
    height: 35px;
    width: 35px;
    font-weight: 600;
    padding: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    background-color: #ffffff;
    transition: background-color 0.3s, color 0.3s;
  }

  .page-number.active {
    background-color: #3b5998;
    color: white;
  }

  .page-number:hover, .arrow-btn:hover {
    background-color: ${hover};
    border-color: #bbb;
  }

  .dots {
    cursor: default;
    font-weight: 900;
    color: #666;
    display: flex;
    justify-content: center;
  }
`;

export default Pagination;
