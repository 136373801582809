import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ unlockTime }) => {
    const calculateTimeLeft = () => {
        const difference = new Date(unlockTime) - new Date();
        if (difference <= 0) return null; // Return null if time is up

        return {
            hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((difference % (1000 * 60)) / 1000),
        };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        if (!timeLeft) return; // Stop the interval if time is up

        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            if (newTimeLeft) {
                setTimeLeft(newTimeLeft);
            } else {
                clearInterval(timer); // Stop the timer if countdown is complete
                setTimeLeft(null);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [unlockTime, timeLeft]);

    return (
        <div>
            {timeLeft ? (
                <span>
                    {`${String(timeLeft.hours).padStart(2, '0')}:${String(timeLeft.minutes).padStart(2, '0')}:${String(timeLeft.seconds).padStart(2, '0')}`}
                </span>
            ) : (
                <span>Unlocked!</span>
            )}
        </div>
    );
};

export default CountdownTimer;
